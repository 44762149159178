<template>
  <div class="custom-label">
    <span>
      <i v-if="value === 'only'" class="iconfont icon-arrow"></i>
      <i
        v-else
        :class="`icon-loop-${value}`"
        class="iconfont icon-loop"
      ></i>
    </span>
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style lang="less" scoped>
.custom-label {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  .iconfont {
    margin-right: 8px;
    font-size: 22px;
  }
  .icon-loop-doesnt {
    position: relative;
    color: @danger;
    &::after {
      content: 'x';
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 10px;
      transform: translate(-50%, -50%);
      font-family: Arial, Helvetica, sans-serif;
    }
  }
  .icon-arrow {
    color: @success;
  }
  .icon-loop-pairs {
    color: @info;
  }
}
</style>
