<template>
  <div class="page--full">
    <router-view />
  </div>
</template>

<script>
import { ACCESS_TOKEN_CACHEKEY } from '@/assets/constant';
export default {
  name: 'App',
  created () {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_CACHEKEY);
    if (accessToken) {
      this.$store.dispatch('authority/fetchProfile');
    }
  },
  unmounted () {
    console.log('main pane unmounted');
  },
};
</script>

<style lang="less">
@import url('~@/assets/css/function.less');
body {
  margin: 0;
  background-color: #fff;
  font-family: Robaga Rounded Regular;
}
</style>

<style lang="less" scoped>
.page--full {
  height: 100vh;
  padding-top: 80px;
  overflow: auto;
}
</style>