import {
  pinImageToIPFS,
  pinFileToIPFS,
  uploadHTMLToOSSByNftId,
} from '@/api';
import { MaterialTypes } from '@/utils';
import template from '@/assets/template/gyroscope';

export default async (
  nft_id,
  seriesNum,
  { collection_name, material_type },
  { attributes, view = {} }
) => {
  const metadata = {
    name: `${collection_name} #${seriesNum}`,
    attributes: attributes.map((attribute) => {
      return {
        trait_type: attribute.trait_type,
        value: attribute.value,
        image: view?.raw.split('?')[0] || ''
      };
    }),
  };

  // if (view.raw) {
  //   const imageUrl = view.raw.split('?')[0];
  //   const filename = imageUrl.split('/').pop();
  //   try {
  //     const [err, data] = await pinImageToIPFS({
  //       url: imageUrl,
  //       filename,
  //     });
  //     if (err) {
  //       metadata.image = '';
  //     } else {
  //       metadata.image = `ipfs://${data.data}`;
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     metadata.image = '';
  //   }
  // }

  if (
    [
      MaterialTypes.TranslateGyroscope,
      MaterialTypes.OpacityGyroscope,
    ].includes(material_type)
  ) {
    metadata.gyroscope = attributes;

    let item_index = 0;
    while (item_index < attributes.length) {
      const item = attributes[item_index];
      if (item?.contents?.[0]?.file_url) {
        const imageUrl = item.contents[0].file_url;
        const filename = imageUrl.split('/').pop();
        try {
          const [err, data] = await pinImageToIPFS({
            url: imageUrl,
            filename,
          });
          if (!err) {
            item.contents[0].file_url = `ipfs://${data.data}`;
          }
        } catch (e) {
          console.log(e);
        }
        delete item.trait_count;
      }
      item_index += 1;
    }

    const html = template.replace(
      '<<raw_data>>',
      JSON.stringify({ attributes })
    );
    try {
      const [err, data] = await pinFileToIPFS({
        data: html,
      });
      if (!err) {
        metadata.art_ipfs = `ipfs://${data.data}`;
      }
    } catch (e) {
      console.log(e);
    }
    try {
      // 上传 html 至 oss
      const [err, data] = await uploadHTMLToOSSByNftId({
        nft_id,
      });
      if (!err) {
        metadata.animation_url = data.data;
      } else {
        metadata.animation_url =
          'https://0xone.art/arts/gyroscope.html?nft_id=' + nft_id;
      }
    } catch (e) {
      metadata.animation_url =
        'https://0xone.art/arts/gyroscope.html?nft_id=' + nft_id;
      console.log(e);
    }
  }
  return metadata;
};
