<template>
  <el-image style="width: 100%; height: 100%" v-if="rawUrl" :src="imgURL" fit="contain">
    <template #placeholder>
      <el-skeleton style="width: 100%; height: 100%" animated>
        <template #template>
          <el-skeleton-item style="width: 100%; height: 100%" variant="image" />
        </template>
      </el-skeleton>
    </template>
  </el-image>
  <div v-else class="token">
    <div v-for="item of traits" :key="item.trait_id" :style="{
  zIndex: item.layer_index,
  backgroundImage: `url(${item.layer_url})`,
}" class="trait">
    </div>
  </div>
</template>

<script>
import { aliOSSImage } from '@/utils';

export default {
  props: {
    rawUrl: String,
    ossStyle: {
      type: String,
      default: 'w400',
    },
    traits: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    imgURL() {
      return aliOSSImage(this.rawUrl, this.ossStyle);
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/function.less";

.token {
  position: relative;
  width: 100%;
  height: 100%;
  .transparent_background(20);

  &>.trait {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
