<template>
  <ConfigWrapper
    :model="formattedModel"
    :save="save"
    type="opacity"
    title="Opacity Gyroscope"
    @open="initModel"
  >
    <template #main>
      <div class="list-wrapper">
        <div class="content">
          <el-form
            :model="form.model"
            ref="form"
          >
            <div
              v-for="item of fields"
              :key="item.prop"
              class="field"
            >
              <span class="field__control">
                <el-icon
                  v-if="form.model[item.prop].angle === null"
                  class="field_control__hide"
                  @click="form.model[item.prop].angle = 0"
                >
                  <View />
                </el-icon>
                <el-icon
                  v-else
                  @click="form.model[item.prop].angle = null"
                >
                  <View />
                </el-icon>
              </span>
              <span class="field__label">{{item.label}}</span>
              <el-form-item :prop="`${item.prop}.angle`">
                <el-radio-group
                  v-model="form.model[item.prop].angle"
                  :disabled="form.model[item.prop].angle === null"
                  size="small"
                >
                  <el-radio-button :label="45">-45</el-radio-button>
                  <el-radio-button :label="0"></el-radio-button>
                  <el-radio-button :label="45"></el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </template>
  </ConfigWrapper>
</template>

<script>
import { mapState } from 'vuex';
import { View } from '@element-plus/icons-vue';
import ConfigWrapper from '@/components/Gyroscope/components/ConfigWrapper';
export default {
  components: {
    ConfigWrapper,
    View,
  },
  props: {
    layers: {
      type: Array,
      required: true,
    },
    save: {
      type: Function,
    },
  },
  data () {
    return {
      form: {
        model: {},
      },
    };
  },
  computed: {
    ...mapState('gyroscope', ['config']),
    fields () {
      return this.layers.map((item) => ({
        label: `Layer${item.layer_index + 1}：${item.type}`,
        prop: item.layer_index,
      }));
    },
    formattedModel () {
      const rawData = Object.entries(this.form.model);
      return rawData
        .filter((item) => item[1].angle !== null)
        .map((item) => {
          const [layer_index, { file_url, angle }] = item;
          return {
            layer_index,
            file_url,
            opacity:
              angle === 0
                ? [
                  [-45, 0],
                  [0, 1],
                  [45, 0],
                ]
                : [
                  [-45, 1],
                  [0, 0],
                  [45, 1],
                ],
          };
        });
    },
  },
  methods: {
    initModel () {
      for (const item of this.layers) {
        // eslint-disable-next-line
        const { opacity } = this.config.find((childItem) => childItem.layer_index == item.layer_index) || {};
        this.form.model[item.layer_index] = {
          angle: opacity !== undefined ? (opacity?.[0]?.[1] === 0 ? 0 : 45) : null,
          file_url: item.layer_url,
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 20px;
  .el-form {
    .field {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .field__control {
        position: relative;
        margin-right: 6px;
        line-height: 0;
        cursor: pointer;
        color: #409eff;
        .field_control__hide {
          color: #bbb;
          &::after {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            display: block;
            width: 14px;
            height: 1px;
            background-color: #bbb;
            transform: rotate(-45deg);
          }
        }
      }
      .field__label {
        width: 25%;
      }
      .el-form-item {
        width: 75%;
        margin: 0;
        :deep(.el-form-item__content) {
          .el-radio-group {
            width: 100%;
            .el-radio-button {
              position: relative;
              display: flex;
              justify-content: center;
              width: calc(100% / 3);
              margin: 0;

              .el-radio-button__inner {
                width: 100%;

                &::after {
                  content: '°隐藏';
                }
              }
            }

            .is-disabled {
              .el-radio-button__inner {
                &::after {
                  content: '°隐藏';
                }
              }
            }

            .el-radio-button.is-active {
              .el-radio-button__inner {
                color: #fff;
                background-color: #409eff;
                border-color: #409eff;
                box-shadow: -1px 0 0 0 #409eff;
                &::after {
                  content: '°显示';
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
