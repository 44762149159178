import { createApp } from "vue";
import "@bilibili/nft-viewer";
import "./assets/css/index.less";
import App from "./App.vue";
import installElementPlus from "./plugins/element";
// import {
//   Setting,
//   ShoppingCart,
//   UploadFilled,
//   Edit,
// } from "@element-plus/icons-vue";
// import rcss from "./plugins/rcss";
// import sentry from './plugins/sentry';
import store from "@/store";
import router from "./router";

function main(el) {
  const app = createApp(App);
  installElementPlus(app);

  // app.component("setting", Setting);
  // app.component("shopping-cart", ShoppingCart);
  // app.component("upload-filled", UploadFilled);
  // app.component("edit", Edit);
  // app.directive("focus", {
  //   //let el-input to auto focus
  //   mounted: (el) => el.children[0].focus(),
  // });
  app.use(router);
  app.use(store);
  app.mount(el);

  // rcss.init();
  // sentry.init();
  return app;
}
// window.main = main;
// window.obj = undefined;
// if (process.env.NODE_ENV === 'development') {
//  main('#app');
// }

main("#app");

export default main;
