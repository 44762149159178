<template>
  <div class="previewer">
    <iframe :src="src"></iframe>
  </div>
</template>

<script>
import { generateGyroscopeData } from '@/components/Gyroscope/utils';
import { aliOSSImage } from '@/utils';
export default {
  props: {
    type: {
      type: [String, Number],
      required: true,
    },
    dataSource: {
      type: [Array, undefined],
      default: () => [],
    },
  },
  computed: {
    rawData () {
      const filteredDataSource = this.dataSource.filter((item) => item.file_url).map(item => ({
        ...item,
        file_url: aliOSSImage(item.file_url, 'w400')
      }));
      return generateGyroscopeData(this.type, filteredDataSource);
    },
    src () {
      const rawDataStr = encodeURIComponent(JSON.stringify(this.rawData));
      return `./gyroscope.html?timestamp=${Date.now()}#raw_data=${rawDataStr}`;
    },
  },
};
</script>

<style lang="less" scoped>
.previewer {
  width: 100%;
  height: calc(100% + 2px);
  background-color: black;
  // &::before {
  //   content: '预览失败';
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   color: white;
  //   transform: translate(-50%, -50%);
  // }

  iframe {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    z-index: 1;
  }
}
</style>
