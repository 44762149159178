import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers } from "ethers";
import { login, getNonce } from '@/api';
import { ACCESS_TOKEN_CACHEKEY } from '@/assets/constant';
const RPC_CONFIG = {
  1: [
    "https://mainnet.infura.io/v3/192aa5fd4a7c4ceb97445cf5cba43e42",
    "https://mainnet.infura.io/v3/102aa35ec48847f2a8d7b2ce8535c0fe",
    "https://mainnet.infura.io/v3/c6cf874733de42fe97f1766789584c89",
    "https://mainnet.infura.io/v3/2ccd842f094949b0a683cee26bb3eed1",
    "https://mainnet.infura.io/v3/5f75c89d36574f5bb82dbbc5c3dd0f76",
    "https://mainnet.infura.io/v3/5f9b344fc1324c98be129122d57590e6",
    "https://mainnet.infura.io/v3/eb20ca37aa984fb8b8427a1b56837672",
  ],
  4: ["https://rinkeby.infura.io/v3/1e90ba38b84149bd9b885e5971813f00"],
};

export const initializeProvider = (connectWay) => {
  return new Promise((resolve, reject) => {
    if (connectWay === 'metaMask') {
      detectEthereumProvider()
        .then(instance => {
          if (instance) {
            resolve(instance);
          }
        })
        .catch(reject);
    } else {
      try {
        const instance = new WalletConnectProvider({
          rpc: RPC_CONFIG,
        });
        resolve(instance);
      } catch (error) {
        reject(error); 
      }
    }
  });
};

export const getBalance = (provider, address) => {
  return new Promise((resolve, reject) => {
    provider.request({
      method: "eth_getBalance",
      params: [address, "latest"],
    })
      .then(balance => resolve(balance))
      .catch(reject);
  });
};

export const getChainId = (provider) => {
  return new Promise((resolve, reject) => {
    provider.request({ method: "eth_chainId" })
      .then(chainId => resolve(parseInt(chainId, 16)))
      .catch(reject);
  });
};

export const sign = (provider, nonce) => {
  return new Promise((resolve, reject) => {
    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    signer.signMessage(nonce)
      .then(res => resolve(res))
      .catch(reject);
  });
};


export const connect = (provider, connectWay) => {
  return new Promise((resolve, reject) => {
    if (connectWay === 'metaMask') {
      provider
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => resolve(accounts))
        .catch((error) => reject(error.message));
    } else {
      provider
        .enable()
        .then(accounts => resolve(accounts))
        .catch((error) => {
          // context.dispatch('updateProvider');
          reject(error.message);
        });
    }
  });
};

export function removeTokenFromLS () {
  localStorage.removeItem(ACCESS_TOKEN_CACHEKEY);
}

export const handleLogin = (address, sign) => {
  return new Promise((resolve, reject) => {
    login({
      address,
      sign
    }).then(([err, data]) => {
      if (err) {
        reject(err);
      } else {
        const { access_token } = data.data;
        localStorage.setItem(ACCESS_TOKEN_CACHEKEY, access_token);
        resolve(data.data);
      }
    });
  });
};

export const fetchNonce = (address) => {
  return new Promise((resolve, reject) => {
    getNonce({
      address
    }).then(([err, data]) => {
      if (err) {
        reject(err);
      } else {
        resolve( data?.data?.nonce);
      }
    });
  });
};

