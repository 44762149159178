<template>
  <el-drawer
    :model-value="visible"
    :show-close="false"
    class="collections_components_drawer"
    destroy-on-close
    @open="initFormModel"
  >
    <template #header>
      <h2>{{ dataSource.collection_name || 'New Collection' }}</h2>
    </template>
    <div class="body">
      <div class="body__description">
        <h3>General Setting</h3>
        <p>Manage your collections base settings.</p>
      </div>
      <div class="form">
        <el-form
          ref="form"
          :model="form.model"
          :rules="form.rules"
          label-position="top"
        >
          <el-form-item label="Type">
            <p class="description">Avatar/Gyroscope</p>
            <el-select
              v-model="form.model.material_type"
              :disabled="dataSource.collection_id"
            >
              <el-option
                v-for="(key, value) in MATERIAL_TYPE"
                :key="key"
                :label="value"
                :value="key"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Name" prop="collection_name">
            <p class="description">Your Collections name.</p>
            <el-input
              v-model="form.model.collection_name"
              placeholder="e.g. My Collection"
            />
          </el-form-item>
          <!-- <el-form-item label="Token Description">
            <p class="description">A human readable description of your Collection</p>
            <el-input
              placeholder="A short description for tokens..."
              disabled
            />
          </el-form-item> -->
          <el-form-item label="Token Count" prop="issue_number">
            <span class="description">
              The amount of Tokens the generator will create for your
              Collection.
            </span>
            <el-input
              :controls="false"
              v-model="form.model.issue_number"
              placeholder="e.g. 1000"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template #footer>
      <el-space>
        <el-button @click="close">Cancel</el-button>
        <el-button :disabled="loading" type="primary" @click="submit">
          Submit
        </el-button>
      </el-space>
    </template>
  </el-drawer>
</template>

<script>
import { ElNotification } from 'element-plus';
import { createCollection, updateCollection } from '@/api';
import { aliOSSImage } from '@/utils';
import { MATERIAL_TYPE } from '@/assets/constant';
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      MATERIAL_TYPE,
      loading: false,
      form: {
        model: {
          material_type: 0,
          collection_name: undefined,
          issue_number: undefined,
        },
        rules: {
          collection_name: [
            {
              required: true,
              message: 'Collection name is required',
              trigger: 'blur',
            },
          ],
          issue_number: [
            {
              required: true,
              message: 'Issue number is required',
              trigger: 'blur',
            },
            {
              pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g,
              message: 'Issue number is number',
              trigger: 'blur',
            },
          ],
        },
      },
    };
  },
  methods: {
    aliOSSImage,
    initFormModel() {
      this.form.model = {
        material_type: 0,
        ...this.dataSource,
      };
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.doCollection().then(([err, res]) => {
            this.loading = false;
            if (!err) {
              if (this.dataSource.collection_id) {
                this.$store.dispatch('collectionList/getList', {
                  page: 1,
                });
                this.close();
                ElNotification({
                  title: 'Success',
                  type: 'success',
                });
              } else {
                const { collection_id } = res.data;
                this.$router.push({
                  path: `/${collection_id}/workspace`,
                });
              }
            }
          });
        }
      });
    },
    doCollection() {
      const { collection_id } = this.dataSource;
      const { issue_number } = this.form.model;
      const data = {
        ...this.form.model,
        issue_number: Number(issue_number),
      };
      if (collection_id) {
        return updateCollection(collection_id, data);
      } else {
        return createCollection(data);
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less" scoped>
@import url('~@/assets/css/function.less');

.body {
  & > .body__description {
    margin-bottom: 24px;

    & > h3 {
      margin-bottom: 8px;
      font-family: Robaga Rounded Black;
    }

    & > p {
      font-size: 14px;
      color: @font-color--light;
    }
  }

  & > .form {
    :deep(.el-form) {
      .el-form-item {
        margin-bottom: 24px;

        .el-form-item__label {
          margin-bottom: 8px;
          line-height: 12px;
          font-size: 12px;
          color: #000;
        }

        .el-form-item__content {
          & > .el-select {
            width: 100%;
          }

          & > .description {
            margin-bottom: 16px;
            line-height: 18px;
            font-size: 12px;
            color: @font-color--light;
          }
        }
      }
    }
  }
}

.cover-uploader {
  width: 100%;

  :deep(.el-upload) {
    & > .el-upload-dragger {
      padding: 10px;

      & > .image {
        width: 100%;
        .transparent_background(20);

        & > img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="less">
.collections_components_drawer {
  font-family: Robaga Rounded Regular;

  .el-drawer__header {
    position: relative;
    margin: 0;

    & > h2 {
      color: #000;
    }
  }
}
</style>
