import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import store from "@/store";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // 非静态页面，需要进行权限控制
  if (!to.meta.static) {
    store.dispatch('authority/getProfile');
  }
});

export default router;