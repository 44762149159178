export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    UPDATE_LIST(state, list) {
      state.list = list;
    },
  },
  actions: {
    getRules({ rootState, commit }) {
      const { collection } = rootState;
      const { id } = collection;
      commit('UPDATE_LIST', []);
      return new Promise((resolve) => {
        const list =
          JSON.parse(localStorage.getItem(`${id}_rules`)) || [];
        commit('UPDATE_LIST', list);
        resolve();
      });
    },
    postRules({ rootState, commit }, payload) {
      const { collection } = rootState;
      const { id } = collection;
      return new Promise((resolve) => {
        localStorage.setItem(`${id}_rules`, JSON.stringify(payload));
        commit('UPDATE_LIST', payload);
        resolve();
      });
    },
  },
};
