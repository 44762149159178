<template>
  <div class="list">
    <ul>
      <li
        v-for="(item, index) of list"
        :key="item.theme_id"
        :class="{
          active: item.theme_id === current?.theme_id,
        }"
        @click="updateCurrentTheme(item)"
      >
        <span>{{ item.theme_name }}</span>
        <el-icon @click.stop="deleteTheme(item, index)">
          <Close />
        </el-icon>
      </li>
      <li class="operator_add">
        <el-button @click="assetsUploader.visible = true">
          + New Theme
        </el-button>
      </li>
    </ul>
  </div>
  <AssetsUploader
    :visible="assetsUploader.visible"
    @submit="addNewTheme"
    @close="assetsUploader.visible = false"
  />
</template>

<script>
import { mapState } from 'vuex';
import { ElMessageBox, ElNotification } from 'element-plus';
import { Close } from '@element-plus/icons-vue';
import AssetsUploader from './components/AssetsUploader';
export default {
  components: {
    Close,
    AssetsUploader,
  },
  data() {
    return {
      assetsUploader: {
        visible: false,
      },
    };
  },
  computed: {
    ...mapState('themes', ['list', 'current']),
  },
  mounted() {
    this.initThemes();
  },
  methods: {
    initThemes() {
      this.$store.dispatch('themes/getList').then((res) => {
        if (!res.data.length) {
          this.updateCurrentTheme();
          this.assetsUploader.visible = true;
        } else {
          this.updateCurrentTheme(res.data[0]);
        }
      });
    },
    updateCurrentTheme(theme = {}) {
      if (
        !theme.theme_id ||
        theme.theme_id !== this.current.theme_id
      ) {
        this.$store.commit('themes/UPDATE_CURRENT', theme);
        this.$emit('select');
      }
    },
    addNewTheme({ theme_name, traits }) {
      this.$store
        .dispatch('themes/addNewTheme', {
          theme_name,
          traits: traits.map((item) => Number(item.trait_id)),
        })
        .then((res) => {
          this.assetsUploader.visible = false;
          const { theme_id } = res[0];
          this.updateCurrentTheme({
            theme_id,
            theme_name,
          });
        });
    },
    deleteTheme({ theme_name }, index) {
      ElMessageBox.confirm(
        `Are you sure you want to archive theme "${theme_name}"? It will no longer appear in your collection.`,
        'Are you sure?',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'el-button--danger danger',
          center: true,
          autofocus: false,
        }
      ).then(() => {
        this.$store
          .dispatch('themes/deleteTheme', { theme_name, index })
          .then(() => {
            ElNotification({
              title: 'Success',
              type: 'success',
            });
            if (theme_name === this.current.theme_name) {
              const preTheme = this.list[index - 1];
              const nextTheme = this.list[index];
              this.updateCurrentTheme(preTheme || nextTheme);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  background-color: indigo;
  overflow-x: auto;
  & > ul {
    display: flex;
    flex-wrap: nowrap;
    & > li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      padding: 0 0 0 8px;
      border-right: 1px solid #000;
      line-height: 32px;
      font-size: 14px;
      color: #fff;
      transition: background 0.3s;
      cursor: pointer;
      & > span {
        padding: 0 12px;
      }
      & > .el-icon {
        height: 100%;
        width: 28px;
        transition: all 0.3s;
        &:hover {
          color: red;
        }
      }
    }
    & > .active {
      background-color: #fff;
      color: #000;
    }
    & > .operator_add {
      display: flex;
      align-items: center;
      padding: 0;

      :deep(.el-input) {
        & > .el-input__wrapper {
          background-color: transparent;
          box-shadow: none;
        }
      }
      .el-button {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: normal;
        color: #fff;
      }
    }
  }
}
</style>
