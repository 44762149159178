<template>
  <div
    :style="{
      width: `${width}px`,
      height: `${height}px`,
      ...style,
    }"
    class="trait-wrapper"
  >
    <div
      :class="{ 'trait--empty': name === EMPTY_ITEM_NAME }"
      class="trait"
    >
      <div
        :style="{
          backgroundImage: `url('${imgSrc}')`,
        }"
        class="image"
      ></div>
    </div>
  </div>
</template>

<script>
import { EMPTY_ITEM_NAME } from '@/assets/constant';
export default {
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      required: true,
      default: '',
    },
    style: {
      type: Object,
    },
  },
  data() {
    return {
      EMPTY_ITEM_NAME,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/css/function.less';
.trait-wrapper {
  flex-shrink: 0;
  overflow: hidden;
  .trait {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 0px;
    .transparent_background(20);

    & > .image {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .trait--empty {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 200%;
      width: 1px;
      background-color: red;
      transform-origin: left top;
      opacity: 0.5;
    }
    &::before {
      right: 0;
      transform: rotate(45deg);
    }
    &::after {
      left: 0;
      transform: rotate(-45deg);
    }
  }
}
</style>
