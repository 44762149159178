import {
  initializeProvider,
  getChainId,
  getBalance,
  connect,
  sign,
  handleLogin,
  fetchNonce,
  removeTokenFromLS,
} from "./helper";
import { getProfile } from "@/api";
import { CONNECT_WAY_CACHEKEY } from "@/assets/constant";
let loopTimer = 0; // loop balance result
export default {
  namespaced: true,
  state: {
    username: "",
    profile: {},
    provider: null,
    account: {
      address: "",
      chainId: 0,
      balance: 0,
    },
    loading: false,
    connectWay: "", // metaMask OR walletConnect
    visible: false,
  },
  getters: {},
  mutations: {
    UPDATE_USERNAME(state, username) {
      state.username = username;
    },
    ukv(state, { key, value }) {
      state[key] = value;
    },
    account(state, value) {
      state.account = {
        ...state.account,
        ...value,
      };
    },

    handleError(state, err) {
      console.log("handleError", err);
      state.loading = false;
    },

    resetAccountData(state) {
      state.account = {
        address: "",
        chainId: 0,
        balance: 0,
      };
      state.loading = false;
      window.clearInterval(loopTimer);
      loopTimer = 0;
      removeTokenFromLS();
    },
  },
  actions: {
    getProfile() {
      return new Promise((resolve, reject) => {
        getProfile().then(([err, res]) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
    },
    async updateProvider(context) {
      const { connectWay } = context.state;
      const provider = await initializeProvider(connectWay);
      context.commit("ukv", {
        key: "provider",
        value: provider,
      });
      provider.on("disconnect", () => context.commit("resetAccountData"));
      provider.on("chainChanged", () => context.commit("resetAccountData"));
      provider.on("accountsChanged", (accounts) =>
        context.dispatch("updateAccountData", accounts)
      );
      return provider;
    },

    async fetchProfile(context) {
      const [err, data] = await getProfile();
      if (err) return;
      const { address } = data.data;
      // reconnect 静默登录流程
      if (!context.state.address || context.state.address !== address) {
        const cookieConnnectWay = localStorage.getItem(CONNECT_WAY_CACHEKEY);

        if (cookieConnnectWay) {
          context.commit("ukv", {
            key: cookieConnnectWay,
          });
          context.dispatch("updateProvider");
          context.dispatch("handleActivate", [cookieConnnectWay, false]);
        }
      }
    },

    async updateAccountData(context, [newAccount]) {
      const { account, provider, connectWay } = context.state;
      if (!newAccount) {
        context.commit("resetAccountData");
        return;
      }

      if (newAccount === account.address) {
        context.commit("ukv", {
          key: "loading",
          value: false,
        });
        return;
      }

      context.commit("account", { address: newAccount });
      context.commit("ukv", {
        key: "visible",
        value: false,
      });

      localStorage.setItem(CONNECT_WAY_CACHEKEY, connectWay);

      try {
        let [balance, chainId] = await Promise.all([
          getBalance(provider, newAccount),
          getChainId(provider),
        ]);
        context.commit("account", {
          balance,
          chainId,
        });
      } catch (error) {
        context.commit("handleError", error);
      }

      if (!loopTimer) {
        loopTimer = window.setInterval(async () => {
          const balance = await getBalance(provider, newAccount);
          context.commit("account", { balance });
        }, 3000);
      }
      context.commit("ukv", {
        key: "loading",
        value: false,
      });
    },

    async handleActivate(context, payload) {
      const [newConnectWay, needSign] = payload;
      console.log(newConnectWay, needSign);
      let { provider, connectWay, loading } = context.state;
      if (loading) return;
      context.commit("ukv", {
        key: "loading",
        value: true,
      });
      // 切换登录类型
      if (!provider || newConnectWay !== connectWay) {
        console.log("reinit");
        context.commit("ukv", {
          key: "connectWay",
          value: newConnectWay,
        });

        context.commit("ukv", {
          key: "provider",
          value: null,
        });

        provider = await context.dispatch("updateProvider"); // reset provider
      }

      try {
        const accounts = await connect(provider, newConnectWay);
        const login = async () => {
          if (!needSign) return context.dispatch("updateAccountData", accounts);

          // nonce
          const nonce = await fetchNonce(accounts[0]);
          if (!nonce) return;

          // sign
          const signRes = await sign(provider, nonce);

          // login
          return await handleLogin(accounts[0], signRes);
        };
        return new Promise((resolve) => {
          login().then((res) => {
            if (res) {
              context.dispatch("updateAccountData", accounts);
              resolve(res);
            }
          });
        });
      } catch (error) {
        // wallet connect 每次都要更新provider来弹出二维码;
        console.log(error);
        if (newConnectWay === "walletConnect")
          context.dispatch("updateProvider");
        removeTokenFromLS();
        context.commit("handleError", error.message);
      }
    },

    handleDeactivate(context) {
      return new Promise((resolve) => {
        const { provider } = context.state;

        context.commit("ukv", {
          key: "loading",
          value: true,
        });

        if (provider && provider.removeListener) {
          localStorage.setItem(CONNECT_WAY_CACHEKEY, "");
          provider.removeListener("disconnect", () =>
            context.commit("resetAccountData")
          );
          provider.removeListener("chainChanged", () =>
            context.commit("resetAccountData")
          );
          provider.removeListener("accountsChanged", (accounts) =>
            context.dispatch("updateAccountData", accounts)
          );
        }
        context.commit("resetAccountData");
        resolve();
      });
    },
  },
};
