<template>
  <div class="main">
    <div class="main__top" style="padding-bottom: 0">
      <div class="main_top__left">
        <h2 class="title">Collections</h2>
        <p class="description">Generate thousands of NFTs in seconds.</p>
      </div>
    </div>
    <div class="main__top border--bottom sticky">
      <div class="form flex">
        <el-button
          class="button--add"
          @click="(drawer.visible = true), (drawer.dataSource = undefined)"
        >
          <i>+</i>
          <span>New Collection</span>
        </el-button>
      </div>
    </div>
    <div class="main__center">
      <div v-loading="loading" class="list">
        <ul v-if="list.length" class="gutter-24">
          <li v-for="(item, index) in list" :key="item.collection_id">
            <CollectionCard
              :dataSource="item"
              @deleteCollection="list.splice(index, 1)"
              @editCollection="
                (drawer.visible = true), (drawer.dataSource = { ...item })
              "
              @updateName="(val) => (item.collection_name = val)"
            />
          </li>
        </ul>
        <el-empty v-else :image-size="200" />
      </div>
    </div>
  </div>
  <DrawerCollection
    :visible="drawer.visible"
    :dataSource="drawer.dataSource"
    @close="drawer.visible = false"
  />
</template>

<script>
import { mapState } from "vuex";
import CollectionCard from "./components/CollectionCard";
import DrawerCollection from "./components/DrawerCollection";

export default {
  components: {
    CollectionCard,
    DrawerCollection,
  },
  data() {
    return {
      loading: true,
      drawer: {
        visible: false,
        dataSource: undefined,
      },
    };
  },
  computed: {
    ...mapState("collectionList", ["list"]),
  },
  mounted() {
    this.getCollections();
  },
  methods: {
    getCollections(page = 1) {
      this.loading = true;
      this.$store.dispatch("collectionList/getList", { page }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/css/function.less");
.main {
  max-width: 1048px;
  padding: 0 24px;
  margin: 60px auto;
  .main__top {
    padding: 24px 0;
    background-color: #fff;
    .main_top__left {
      & > .title {
        margin-bottom: 20px;
        line-height: 36px;
        font-family: "Robaga Rounded Black";
        font-size: 42px;
        color: @font-color;
      }
      & > .description {
        line-height: 18px;
        font-family: "Robaga Rounded Regular";
        font-size: 18px;
        color: @font-color--light;
      }
    }
  }
  .main__center {
    margin-top: 32px;
    & > .list {
      & > ul {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

.button--add {
  font-family: "Robaga Rounded Regular";
  & > span {
    & > i {
      margin-right: 4px;
      font-style: normal;
    }
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.flex {
  display: flex;
}
.gutter-10 {
  .gutter(10px, 0);
}
.gutter-24 {
  .gutter(24px, 24px);
}
.border--bottom {
  border-bottom: 1px solid #000;
}
</style>
