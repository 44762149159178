import { getFileByEntry } from '@/utils';

// Drop handler function to get all files
export const getAllFileEntries = async (dataTransferItemList) => {
  let fileEntries = [];
  // Use BFS to traverse entire directory/file structure
  let queue = [];
  for (let i = 0; i < dataTransferItemList.length; i++) {
    queue.push(dataTransferItemList[i].webkitGetAsEntry());
  }
  while (queue.length > 0) {
    let entry = queue.shift();
    if (entry.isFile) {
      const file = await getFileByEntry(entry);
      entry.raw = file;
      fileEntries.push(entry);
    } else if (entry.isDirectory) {
      let reader = entry.createReader();
      queue.push(...(await readAllDirectoryEntries(reader)));
    }
  }
  return fileEntries;
};
  
// Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
export const readAllDirectoryEntries =async (directoryReader) => {
  let entries = [];
  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }
  return entries;
};
  
// Wrap readEntries in a promise to make working with readEntries easier
export const readEntriesPromise = async (directoryReader) => {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.log(err);
  }
};