<template>
  <TopBar />
  <router-view />
</template>

<script>
import TopBar from '@/components/TopBar';
export default {
  name: 'BlankLayout',
  components: {
    TopBar,
  },
};
</script>
