<template>
  <ConfigWrapper
    :model="formattedModel"
    :save="save"
    type="translate"
    title="Translate Gyroscope"
    @open="initModel"
  >
    <template #main>
      <div class="list-wrapper">
        <div class="title">
          <span
            v-for="item of titles"
            :key="item"
          >{{item}}</span>
        </div>
        <div class="content">
          <el-form
            :model="form.model"
            ref="form"
          >
            <div
              v-for="item of formItems"
              :key="item.prop"
              class="field"
            >
              <template v-if="form.model[item.prop]">
                <span class="field__label">{{item.label}}</span>
                <el-form-item
                  v-for="childItem of item.fields"
                  :key="childItem"
                  :prop="`${item.prop}.${childItem.prop}`"
                >
                  <el-input-number
                    v-model="form.model[item.prop][childItem.prop]"
                    :min="-100"
                    :max="100"
                    size="small"
                  />
                </el-form-item>
              </template>
            </div>
          </el-form>
        </div>
      </div>
    </template>
  </ConfigWrapper>
</template>

<script>
import { mapState } from 'vuex';
import ConfigWrapper from '@/components/Gyroscope/components/ConfigWrapper';
const titles = ['x轴偏移量(%)', 'y轴偏移量(%)', '缩放(倍)'];
export default {
  components: {
    ConfigWrapper,
  },
  props: {
    layers: {
      type: Array,
      required: true,
    },
    save: {
      type: Function,
    },
  },
  data () {
    return {
      titles,
      form: {
        model: {},
      },
    };
  },
  computed: {
    ...mapState('gyroscope', ['config']),
    formItems () {
      return this.layers.map((item) => ({
        label: `Layer${item.layer_index + 1}：${item.type}`,
        prop: item.layer_index,
        fields: [{ prop: 'translateX' }, { prop: 'translateY' }, { prop: 'scale' }],
      }));
    },
    formattedModel () {
      const rawData = Object.entries(this.form.model);
      return rawData.map((item) => {
        const [layer_index, { file_url, translateX = 0, translateY = 0, scale = 1 }] = item;
        return {
          layer_index,
          file_url,
          translateX,
          translateY,
          scale,
        };
      });
    },
  },
  methods: {
    initModel () {
      for (const item of this.layers) {
        // eslint-disable-next-line
        const { translateX = 0, translateY = 0, scale = 1 } = this.config.find((childItem) => childItem.layer_index == item.layer_index) || {};
        this.form.model[item.layer_index] = {
          translateX,
          translateY,
          scale,
          file_url: item.layer_url,
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0;
  background-color: #f5f7fa;
  border-bottom: 1px solid rgb(220, 223, 230);

  & > span {
    flex-shrink: 0;
    width: 25%;
    text-align: center;
  }
}
.content {
  padding: 20px;
  .el-form {
    .field {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .field__label {
        width: 25%;
      }
      .el-form-item {
        width: 25%;
        margin: 0;
        :deep(.el-form-item__content) {
          justify-content: center;
          .el-input-number__decrease,
          .el-input-number__increase {
            bottom: 2px;
          }
        }
      }
    }
  }
}
</style>
