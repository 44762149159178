<template>
  <el-dropdown
    :hide-on-click="false"
    trigger="click"
    popper-class="views_workspace_trait_rules_dropdown"
  >
    <div class="selector">
      <el-badge
        v-if="selectedItems.length"
        :value="selectedItems.length"
        :hidden="!multiple || selectedItems.length < 2"
      >
        <ul>
          <li
            v-for="childItem in selectedItems"
            :key="childItem.trait_id"
          >
            <Trait
              :width="40"
              :height="40"
              :name="childItem.value"
              :img-src="aliOSSImage(childItem.layer_url, 'w400')"
            />
            <div class="title">
              <p class="subtitle">{{ childItem.type }}</p>
              <p>{{ childItem.value }}</p>
            </div>
          </li>
        </ul>
      </el-badge>
      <div v-else class="placeholder">Select a Trait</div>
    </div>
    <template #dropdown>
      <el-tabs
        tab-position="left"
        type="border-card"
        class="tabs-in-dropdown"
      >
        <el-tab-pane v-for="item of options" :key="item[0]">
          <template #label>
            <el-badge
              :hidden="
                !selectedItems.filter(
                  (childItem) => childItem.type === item[0]
                ).length
              "
              :value="
                selectedItems.filter(
                  (childItem) => childItem.type === item[0]
                ).length
              "
            >
              <span>{{ item[0] }}</span>
            </el-badge>
          </template>
          <ul class="trait-list">
            <li
              v-for="childItem in item[1]"
              :key="childItem.trait_id"
              :class="{
                active: selectedItems.find(
                  (findItem) =>
                    findItem.trait_id === childItem.trait_id
                ),
                notAllow: childItem.notAllow,
              }"
              @click="
                !childItem.notAllow && $emit('select', childItem)
              "
            >
              <Trait
                :width="40"
                :height="40"
                :name="childItem.value"
                :img-src="aliOSSImage(childItem.layer_url, 'w400')"
              />
              <div class="title">
                {{ childItem.value }}
              </div>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </template>
  </el-dropdown>
</template>
<script>
import { mapState } from 'vuex';
import Trait from '@/components/Trait';
import { aliOSSImage } from '@/utils';
export default {
  components: {
    Trait,
  },
  props: {
    value: {
      type: [Array, String],
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    multiple: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('traits', {
      traits: ({ list }) => list,
    }),
    selectedItems() {
      if (this.multiple) {
        return (this.value || []).map((item) =>
          this.traits.find((findItem) => findItem.trait_id === item)
        );
      } else if (this.value) {
        return [
          this.traits.find(
            (findItem) => findItem.trait_id === this.value
          ),
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    aliOSSImage,
  },
};
</script>

<style lang="less" scoped>
.selector {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  & > .el-badge {
    width: 100%;
    & > ul {
      position: relative;
      & > li {
        position: absolute;
        top: 4px;
        left: 4px;
        right: -4px;
        bottom: -4px;
        display: flex;
        align-items: center;
        border: 1px solid @border-color;
        border-radius: 8px;
        background-color: @white;
        overflow: hidden;
        cursor: pointer;

        &:first-child {
          position: static;
        }

        & > .trait-wrapper {
          box-shadow: 0 0 0 1px @border-color;
        }

        & > .title {
          margin: 0 12px;
          line-height: 16px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          color: @black;

          & > .subtitle {
            margin-bottom: 4px;
            line-height: 12px;
            font-size: 10px;
            color: @font-color--light;
          }
        }
      }
    }
  }
  & > .placeholder {
    text-align: center;
    cursor: pointer;
  }
}

.tabs-in-dropdown {
  height: 338px;
  border: none;

  :deep(.el-tabs__header) {
    height: 100%;
    & > .el-tabs__nav-wrap {
      & > .el-tabs__nav-scroll {
        & > .el-tabs__nav {
          & > .el-tabs__item {
            padding: 0 20px 0 30px;
            & > .el-badge {
              & > .el-badge__content {
                top: 50%;
                left: -4px;
                right: auto;
                line-height: 13px;
                transform: translate(-100%, -50%);
              }
            }
          }
        }
      }
    }
  }

  :deep(.el-tabs__content) {
    height: 100%;
    padding: 0;
    overflow: hidden;
    & > .el-tab-pane {
      height: 100%;
      padding: 15px;
      overflow: auto;
      & > .trait-list {
        & > li {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          border: 1px solid @border-color;
          border-radius: 8px;
          overflow: hidden;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            border-color: @primary;
            & > .trait-wrapper {
              box-shadow: 0 0 0 1px @primary;
            }
          }

          & > .trait-wrapper {
            box-shadow: 0 0 0 1px @border-color;
            background-color: @white;
            transition: all 0.3s;
          }

          & > .title {
            margin: 0 12px;
          }
        }
        & > .active {
          background-color: @primary;
          border-color: @primary;
          color: @white;
          & > .trait-wrapper {
            box-shadow: 0 0 0 1px @primary;
          }
        }
        & > .notAllow {
          opacity: 0.2;
          cursor: not-allowed;
          &:hover {
            border-color: @border-color;
            & > .trait-wrapper {
              box-shadow: 0 0 0 1px @border-color;
            }
          }
        }
      }
    }
  }
}
</style>
