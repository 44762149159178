<template>
  <el-dialog
    :model-value="visible"
    :title="ready ? 'Check assets' : 'Upload assets'"
    :width="ready ? '70vw' : '30vw'"
    :close-on-click-modal="!ready"
    :close-on-press-escape="!ready"
    :show-close="!ready"
    @opened="opened"
    @closed="closed"
  >
    <div class="body">
      <!-- 此处 v-show 的原因是，获取到 upload dom 后需要对其 webkitdirectory 属性改动为 true，
      避免 v-if 造成的重复获取 dom，直接 v-show-->
      <el-upload
        v-show="!ready"
        :show-file-list="false"
        :auto-upload="false"
        :on-change="uploadFile"
        ref="uploaderEl"
        multiple
        drag
        @drop="dropFile"
      >
        <el-icon class="el-icon--upload">
          <el-icon>
            <UploadFilled />
          </el-icon>
        </el-icon>
        <div class="el-upload__text">
          Drag n’ Drop folder here or
          <em>click to upload</em>
          <br />
        </div>
        <div class="el-upload__text notes" @click.stop>
          First time?
          <a href="//0xone.art/file/Space%20Sneks%20Society.zip">
            <em>Try demo assets</em>
          </a>
        </div>
      </el-upload>
      <div v-if="ready" class="file_list">
        <div class="pre-upload-table">
          <h3 class="table-header">{{ themeName }}</h3>
          <PreUploadTable
            :dataSource="formattedFiles"
            @delete="({ name }) => deletedFileNames.push(name)"
          />
        </div>
        <div class="controls">
          <el-button :disabled="loading" @click="clearFile">
            <el-icon>
              <ArrowLeft />
            </el-icon>
            back
          </el-button>
          <ButtonWithProgress
            :loading="loading"
            :progress="uploadedFileCount"
            :total="filteredFiles.length"
            label="submit"
            class="btn_with_progress"
            @start="validateAssets"
          />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { mapState } from 'vuex';
import { ElNotification, ElMessageBox } from 'element-plus';
import { UploadFilled, ArrowLeft } from '@element-plus/icons-vue';
import { uploadToBFS, uploadTraitsToCollection } from '@/api';
import ButtonWithProgress from '@/components/ButtonWithProgress';
import PreUploadTable from './PreUploadTable';
import { getAllFileEntries } from './analysisFolder';
export default {
  components: {
    UploadFilled,
    ArrowLeft,
    ButtonWithProgress,
    PreUploadTable,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    const uploaderEl = ref();
    return {
      uploaderEl,
    };
  },
  data() {
    return {
      loading: false,
      files: [],
      page: 0,
      uploadedFileCount: 0,
      deletedFileNames: [],
      traits: [],
    };
  },
  computed: {
    ...mapState('collection', {
      collection_id: ({ id }) => id,
      collection_info: ({ info }) => info,
    }),
    ...mapState('themes', {
      themeList: ({ list }) => list,
    }),
    ready() {
      return this.files?.length;
    },
    formattedFiles() {
      const error = ({
        file_name,
        file_type,
        layer_index,
        layer_name,
      }) => {
        let msg;
        switch (true) {
          case (layer_index !== 0 && !layer_index) || !layer_name:
            msg = 'Analysis layer wrong';
            break;
          case !file_name:
          case !['png', 'jpg', 'jpeg', 'webp'].includes(file_type):
            msg = 'Wrong file type';
            break;
          default:
            break;
        }
        return msg;
      };
      const newData = this.files.map((item) => {
        const { name, fullPath } = item;
        const { webkitRelativePath } = item.raw;
        const [theme_name, layer = ''] = (
          webkitRelativePath || fullPath
        )
          .split('/')
          .filter((item) => item);
        const [layer_index = '', layer_name] = layer.split('_');
        const [file_name, file_type] = name.split('.');
        return {
          ...item,
          theme_name,
          layer_index: Number(layer_index),
          layer_name,
          file_name,
          file_type,
          error: error({
            file_name,
            file_type,
            layer_index,
            layer_name,
          }),
        };
      });
      return newData;
    },
    themeName() {
      return this.formattedFiles?.[0]?.theme_name;
    },
    filteredFiles() {
      return this.formattedFiles.filter(
        (item) => !this.deletedFileNames.includes(item.name)
      );
    },
  },
  methods: {
    validateAssets() {
      const error = this.filteredFiles.filter(
        (item) => item.error
      ).length;
      const sameThemeName = this.themeList.find(
        (item) => item.theme_name === this.themeName
      );
      if (error) {
        ElNotification({
          title: 'Exist files with error, delete them first',
          type: 'error',
        });
      } else if (sameThemeName) {
        ElMessageBox.confirm(
          `There already exsit "${this.themeName}", and it will be covered. Continue?`,
          'Warning',
          {
            type: 'warning',
          }
        )
          .then(() => {
            this.startUpload(this.filteredFiles);
          })
          .catch(() => {});
      } else {
        this.startUpload(this.filteredFiles);
      }
    },
    startUpload(files) {
      this.loading = true;
      this.uploadAssets(files);
    },
    uploadAssets(files) {
      const { raw, file_name, layer_index, layer_name } =
        files[this.page];
      const form = new FormData();
      form.append('file', raw);
      uploadToBFS(form)
        .then(
          ([err, res]) => {
            if (!err) {
              uploadTraitsToCollection(this.collection_id, {
                value: file_name,
                layer_url: res.data.location,
                layer_index,
                type: layer_name,
              }).then(
                ([err, res]) => {
                  if (err) {
                    this.resetStatus();
                  } else {
                    this.traits.push(res.data);
                    this.uploadedFileCount++;
                    if (
                      this.uploadedFileCount <
                        this.filteredFiles.length &&
                      this.loading
                    ) {
                      this.page++;
                      this.uploadAssets(files);
                    } else {
                      this.loading = false;
                      this.$emit('submit', {
                        theme_name: this.themeName,
                        traits: this.traits,
                      });
                    }
                  }
                },
                () => {
                  this.resetStatus();
                }
              );
            } else {
              this.resetStatus();
            }
          },
          () => {
            this.resetStatus();
          }
        )
        .catch(() => {
          this.resetStatus();
        });
    },
    opened() {
      this.$nextTick(() => {
        const inputEl =
          this.uploaderEl?.$el?.querySelector('.el-upload__input') ||
          {};
        inputEl.webkitdirectory = true;
        inputEl.mozdirectory = true;
      });
    },
    closed() {
      this.files = [];
      this.resetStatus();
    },
    resetStatus() {
      this.loading = false;
      this.page = 0;
      this.uploadedFileCount = 0;
      this.traits = [];
    },
    async dropFile(e) {
      e.preventDefault();
      const files = await getAllFileEntries(e.dataTransfer.items);
      for (const item of files) {
        this.uploadFile(item);
      }
    },
    uploadFile(file) {
      const { name = '' } = file;
      const [file_name, file_type] = name.split('.');
      if (file_name && file_type) {
        this.files.push(file);
      }
      return false;
    },
    clearFile() {
      this.files = [];
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  padding: 8px 0 24px 0;
}

.file_list {
  & > .pre-upload-table {
    margin-bottom: 16px;
  }

  & > .controls {
    text-align: center;

    & > .btn_with_progress {
      width: 100px;
      background-color: @primary;
      border-color: @primary;
      overflow: hidden;
      color: @white;
    }

    & > .btn_with_progress.is-disabled {
      border: none;
    }
  }
}

.el-upload__text.notes {
  margin-top: 30px;
  margin-bottom: -20px;
  font-size: 12px;

  & > a {
    color: #409eff;
  }
}

.pre-upload-table {
  .table-header {
    width: 100%;
    height: 40px;
    background-color: #f5f7fa;
    border: 1px solid #ebeef5;
    border-bottom: none;
    line-height: 40px;
    text-align: center;
  }
}
</style>
