<template>
  <div class="wallet-wrap">
    <button
      v-if="!account.address"
      class="wallet-entrance"
      @click="visible = true"
    >
      CONNECT WALLET
    </button>
    <button v-else class="wallet-entrance" @click="deactivate">
      DISCONNECT
    </button>
    <!-- <br />
    <span> account: {{ account }}</span>-->
    <el-dialog
      :model-value="visible"
      class="el-dialog__wallet"
      append-to-body
      @close="visible = false"
      :show-close="false"
      top="0"
    >
      <div class="wallet-modal">
        <header>
          <span>Connect Your Wallet</span>
          <svg viewBox="0 0 16 16" @click="visible = false">
            <path
              fill="#fff"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.0711 3.05021C15.6569 2.46442 15.6569 1.51467 15.0711 0.928888C14.4853 0.343102 13.5355 0.343101 12.9497 0.928888L8 5.87863L3.05025 0.928887C2.46447 0.343101 1.51472 0.3431 0.928932 0.928887C0.343146 1.51467 0.343146 2.46442 0.928932 3.05021L5.87868 7.99996L0.928932 12.9497C0.343146 13.5355 0.343146 14.4852 0.928932 15.071C1.51472 15.6568 2.46447 15.6568 3.05025 15.071L8 10.1213L12.9497 15.071C13.5355 15.6568 14.4853 15.6568 15.0711 15.071C15.6569 14.4852 15.6569 13.5355 15.0711 12.9497L10.1213 7.99996L15.0711 3.05021Z"
            />
          </svg>
        </header>
        <ul class="btn-list">
          <li>
            <button class="connect-btn" @click="activateMetaMask">
              <div>
                <img
                  v-if="!loading"
                  src="@/assets/images/metamask.png"
                  alt
                />
                <span v-else class="loading">
                  <img src="@/assets/images/loading.png" alt />
                </span>
              </div>
              metamask
            </button>
          </li>
          <li>
            <button
              class="connect-btn"
              @click="activate('walletConnect')"
            >
              <div>
                <img
                  v-if="!loading"
                  src="@/assets/images/walletconnect.png"
                  alt
                />
                <span v-else class="loading">
                  <img src="@/assets/images/loading.png" alt />
                </span>
              </div>
              Wallet Connect
            </button>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
  <InstallMetaMask
    :visible="dialogInstallMetaMask.visible"
    @close="dialogInstallMetaMask.visible = false"
  />
</template>

<script>
import { mapState } from 'vuex';
import InstallMetaMask from './components/InstallMetaMask';

export default {
  components: {
    InstallMetaMask,
  },
  data() {
    return {
      visible: false,
      dialogInstallMetaMask: {
        visible: false,
      },
    };
  },
  computed: {
    ...mapState('authority', ['account', 'loading']),
  },
  methods: {
    activateMetaMask() {
      this.visible = false;
      if (window.ethereum) {
        this.activate('metaMask');
      } else {
        this.dialogInstallMetaMask.visible = true;
      }
    },
    activate(connectWay) {
      this.visible = false;
      this.$store
        .dispatch('authority/handleActivate', [connectWay, true])
        .then(() => {
          this.$router.push('/collections');
        });
    },
    deactivate() {
      this.$store.dispatch('authority/handleDeactivate').then(() => {
        this.$router.push('/');
      });
    },
  },
};
</script>

<style lang="less">
.wallet-entrance {
  height: 48px;
  min-width: 64px;
  padding: 6px 24px;
  border: 4px solid #000;
  border-radius: 28px;
  background-color: #fff;
  font-family: 'Robaga Rounded Black';
  font-size: 18px;
  line-height: 18px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: @primary;
  }
}

.el-dialog__wallet {
  .el-dialog__header,
  .el-dialog__footer {
    display: none;
  }
}

.wallet-modal {
  position: fixed !important;
  right: 0;
  top: 0;
  padding: 0 80px 60px;
  border-bottom-left-radius: 30px;
  background-color: rgb(24 25 28);
  z-index: 9999;
  font-family: 'Robaga Rounded Regular';

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 336px;
    padding: 47px 0 40px;
    color: #fff;

    span {
      display: block;
      font-size: 16px;
    }

    svg {
      display: block;
      width: 30px;
      height: 30px;
      padding: 7px;
      cursor: pointer;
      transition: transform 0.5s ease-out !important;

      &:hover {
        transform: rotate(180deg);
      }
    }
  }

  .btn-list {
    margin-top: -16px;

    li {
      margin-top: 16px;
    }
  }

  .connect-btn {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0px 28px 0 32px;
    border: 1px solid #fff;
    background-color: transparent;
    background-image: none;
    border-radius: 9999px;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    color: #fff;

    > div {
      position: relative;
      width: 32px;
      margin-right: 8px;

      .loading {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        overflow: hidden;
        transform: translateY(-50%);

        img {
          display: block;
          height: 14px;
          width: 14px;
          animation: loading 1s linear infinite;
        }

        @keyframes loading {
          from {
            transform: rotate(0deg);
          }

          to {
            transform: rotate(1turn);
          }
        }
      }

      > img {
        display: block;
        width: 32px;
        height: 32px;
      }
    }
  }
}
</style>
