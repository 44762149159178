<template>
  <div class="wrapper">
    <el-button
      :icon="Plus"
      class="btn--fixed"
      @click="list.push({ ...defaultRule })"
    >
      New Rule
    </el-button>
    <h2 class="title">Rules</h2>
    <p class="sub-title">
      Use rules to control what traits can be combined with other
      traits.
    </p>
    <div class="main">
      <div class="list">
        <ul v-if="list.length">
          <li v-for="(item, index) of list" :key="index">
            <el-switch
              v-model="item.active"
              size="small"
              class="operator"
            />
            <div class="rule-wrapper">
              <Cascader
                :value="item.key"
                :options="generatedKeyOptions(index)"
                @select="(val) => selectKey(val, item, index)"
              />
              <el-dropdown trigger="click">
                <CustomLabel
                  :value="item.operator"
                  :label="operatorOptions[item.operator]"
                  class="selector"
                  style="min-width: 186px"
                />
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="(value, key) in operatorOptions"
                      :key="key"
                      @click="
                        () => {
                          item.operator = key;
                          selectOperator(item, index);
                        }
                      "
                    >
                      <CustomLabel :value="key" :label="value" />
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <Cascader
                :value="item.values"
                :options="generatedValueOptions(item, index)"
                multiple
                @select="(val) => selectValues(val, item)"
              />
            </div>
            <el-dropdown class="operator" trigger="click">
              <el-icon><MoreFilled /></el-icon>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="deleteRule(index)">
                    <el-icon><Delete /></el-icon>
                    Delete
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </li>
        </ul>
        <el-empty v-else />
      </div>
    </div>
    <div class="footer">
      <el-button @click="$emit('close')">Cancel</el-button>
      <el-button type="primary" @click="submit">Confirm</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Plus, Delete, MoreFilled } from '@element-plus/icons-vue';
import Cascader from './components/Cascader';
import CustomLabel from './components/CustomLabel';
export default {
  components: {
    Delete,
    MoreFilled,
    Cascader,
    CustomLabel,
  },
  data() {
    return {
      Plus,
      list: [],
      defaultRule: {
        active: true,
        key: '', // trait_id
        operator: 'doesnt', // doesn't mix with, only mixs with, always pairs with
        values: [], // trait_ids
      },
    };
  },
  computed: {
    ...mapState('traitRules', {
      rules: ({ list }) => list,
    }),
    ...mapState('traits', {
      traits: ({ list }) => list,
    }),
    ...mapGetters('traits', ['layers']),
    generatedKeyOptions() {
      return (activeIndex) => {
        // 不允许重复的 key
        const keys = this.list
          .filter((item, index) => index !== activeIndex)
          .map((item) => item.key);
        return [...this.layers].map((item) => {
          const traits = item[1].map((childItem) => ({
            ...childItem,
            notAllow: keys.includes(childItem.trait_id),
          }));
          return [item[0], traits];
        });
      };
    },
    operatorOptions() {
      return {
        doesnt: "Doesn't mix with",
        only: 'Only mixs with',
        pairs: 'Always pairs with',
      };
    },
    generatedValueOptions() {
      return ({ key }, activeIndex) => {
        const currentKey = this.traits.find(
          (item) => item.trait_id === key
        );
        const usedKeys = this.list
          .filter(
            (item, index) =>
              index !== activeIndex && item.values.includes(key)
          )
          .map((item) => item.key);
        const usedValues = this.list
          .filter(
            (item, index) =>
              index !== activeIndex && item.operator === 'pairs'
          )
          .map((item) => item.values)
          .flat();
        return [...this.layers]
          .filter((item) => item[0] !== currentKey?.type)
          .map((item) => {
            const traits = item[1].map((childItem) => ({
              ...childItem,
              notAllow: [...usedKeys, ...usedValues].includes(
                childItem.trait_id
              ),
            }));
            return [item[0], traits];
          });
      };
    },
  },
  mounted() {
    this.initList();
  },
  methods: {
    initList() {
      this.list = JSON.parse(JSON.stringify([...this.rules]));
      if (!this.list.length) {
        this.list.push(this.defaultRule);
      }
    },
    selectKey(val, item, index) {
      this.list.splice(index, 1, {
        ...item,
        key: val.trait_id,
        values: [],
      });
    },
    selectOperator({ operator, values }, activeIndex) {
      if (operator === 'pairs') {
        this.list.forEach((item, index) => {
          if (index !== activeIndex) {
            item.values = item.values.filter(
              (childItem) => !values.includes(childItem)
            );
          }
        });
      }
    },
    selectValues({ trait_id }, { values }) {
      const index = values.findIndex((item) => item === trait_id);
      if (index === -1) {
        values.push(trait_id);
      } else {
        values.splice(index, 1);
      }
    },
    deleteRule(index) {
      this.list.splice(index, 1);
    },
    submit() {
      const data = this.list.filter(
        (item) => item.key && item.values.length
      );
      this.$store.dispatch('traitRules/postRules', data);
      this.$emit('close');
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  position: relative;
  & > .btn--fixed {
    position: absolute;
    top: 0;
    right: 0;
  }
  & > .title {
    height: 48px;
    margin-bottom: 8px;
    line-height: 48px;
    font-size: 32px;
    color: @font-color;
  }
  & > .subtitle {
    height: 24px;
    line-height: 24px;
    color: @font-color--light;
  }
  & > .main {
    height: 50vh;
    padding: 0 20px;
    margin-top: 24px;
    margin-left: -20px;
    margin-right: -18px;
    overflow: auto;
    content-visibility: auto;
    & > .list {
      & > ul {
        overflow: hidden;
        & > li {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 68px;
          margin-bottom: 24px;
          border: 1px solid @border-color;
          border-radius: 8px;
          overflow: hidden;

          & > .el-switch {
            border-left: none !important;
            border-right: 1px solid @border-color;
          }

          & > .rule-wrapper {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding: 0 24px;

            :deep(.el-dropdown) {
              flex: 1;
              & > .custom-label {
                justify-content: center;
              }
            }
          }
          & > .operator {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 12px;
            border-left: 1px solid @border-color;
            cursor: pointer;
            & > .el-icon {
              height: 100%;
            }
          }
        }
      }
    }
  }
  & > .footer {
    padding: 20px 0;
    text-align: right;
  }
}
</style>

<style lang="less">
.views_workspace_trait_rules_dropdown {
  .el-dropdown-menu {
    .active {
      position: relative;
      color: @primary;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: @primary;
      }
    }
  }
}
</style>
