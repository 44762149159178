import { BaseLayout, BlankLayout } from "@/layouts";

export default [
  {
    path: "/",
    component: BlankLayout,
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("@/views/Index"),
        meta: {
          title: "NFT FACTORY",
          static: true,
        },
      },
      {
        path: "/collections",
        name: "collections",
        component: () => import("@/views/Collections"),
        meta: {
          title: "藏品集合",
        },
      },
    ],
  },
  {
    path: "/:collection_id",
    redirect: "/:collection_id/workspace",
    component: BaseLayout,
    children: [
      {
        path: "/:collection_id/workspace",
        name: "workspace",
        component: () => import("@/views/Workspace"),
        meta: {
          title: "工作区",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/views/404"),
    meta: {
      static: true,
    },
  },
];
