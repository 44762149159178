<template>
  <ul class="list">
    <el-upload
      :show-file-list="false"
      :accept="accept"
      :before-upload="(file) => addAndUpdateTrait(file)"
      class="add"
      @click.stop
    >
      <el-button>+ New trait</el-button>
    </el-upload>
    <li
      v-for="item of list"
      :key="item.trait_id"
      :class="{
        hidden: item.hidden,
        active: combination && combination.has(item.trait_id),
      }"
      @click="select(item)"
    >
      <el-popover
        v-if="item.editable"
        placement="top"
        trigger="hover"
        width="226px"
        popper-class="wokspace_traits_popover"
      >
        <template #reference>
          <el-upload
            :show-file-list="false"
            :disabled="item.loading"
            :before-upload="(file) => addAndUpdateTrait(file, item)"
            :accept="accept"
            class="thumbnail"
            @click.stop
          >
            <Trait
              :width="20"
              :height="20"
              :name="item.value"
              :img-src="item.blobUrl"
            />
            <el-icon v-if="item.loading" class="icon-loading">
              <Loading />
            </el-icon>
            <el-icon v-else class="icon-upload">
              <UploadFilled />
            </el-icon>
          </el-upload>
        </template>
        <Trait
          :width="200"
          :height="200"
          :name="item.value"
          :img-src="item.blobUrl"
        />
      </el-popover>
      <Trait
        v-else
        :width="20"
        :height="20"
        :name="item.value"
        :img-src="item.blobUrl"
      />
      <div class="info">
        <span class="title">
          <el-input
            :model-value="
              currentTrait.id === item.trait_id
                ? currentTrait.model.name
                : item.value
            "
            :placeholder="item.value"
            :disabled="!item.editable"
            @focus="
              (currentTrait.id = item.trait_id),
                (currentTrait.model.name = item.value)
            "
            @blur="currentTrait.model.name = item.value"
            @input="(val) => (currentTrait.model.name = val)"
            @keyup.enter="
              updateTraitName(item, currentTrait.model.name)
            "
            @click.stop
          ></el-input>
          <span class="placeholder">{{
            currentTrait.id === item.trait_id
              ? currentTrait.model.name || item.value
              : item.value
          }}</span>
        </span>
        <span class="id">#{{ item.trait_id }}</span>
        <!-- <span>{{ item.trait_count }}</span> -->
      </div>
      <div class="action" @click.stop>
        <span
          v-if="item.hidden !== undefined"
          class="eye"
          @click="
            () => {
              item.hidden = !item.hidden;
              if (item.hidden) select(item);
            }
          "
        >
          <el-icon v-if="item.hidden"><Hide /></el-icon>
          <el-icon v-else><View /></el-icon>
        </span>
        <el-icon
          v-if="item.editable"
          style="background-color: #f56c6c"
          class="delete"
          @click="deleteTrait(item)"
        >
          <Delete />
        </el-icon>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
import { ElMessageBox, ElNotification } from 'element-plus';
import {
  UploadFilled,
  Delete,
  View,
  Hide,
  Loading,
} from '@element-plus/icons-vue';
import Trait from '@/components/Trait';
import { uploadToBFS } from '@/api';
import { SUPPERT_FILE_SUFFIX } from '@/assets/constant';

export default {
  components: {
    UploadFilled,
    Delete,
    // eslint-disable-next-line vue/no-reserved-component-names
    View,
    Hide,
    Loading,
    Trait,
  },
  props: {
    layer: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      SUPPERT_FILE_SUFFIX,
      currentTrait: {
        id: undefined,
        model: {
          name: undefined,
        },
      },
    };
  },
  computed: {
    ...mapState('collection', {
      collectionInfo: ({ info }) => info,
    }),
    ...mapState('traits', ['combination']),
    accept() {
      return this.SUPPERT_FILE_SUFFIX[
        this.collectionInfo.material_type
      ]
        ?.map((item) => `image/${item}`)
        ?.join(',');
    },
  },
  methods: {
    select(trait) {
      this.$store.dispatch('traits/selectTrait', trait);
    },
    addAndUpdateTrait(file, trait = {}) {
      trait.loading = true;
      const { trait_id, value } = trait;
      const name = value || file.name.split('.')[0];
      const { layer_index, layer_name } = this.layer;
      const form = new FormData();
      form.append('file', file);
      uploadToBFS(form).then(([err, res]) => {
        if (!err) {
          this.$store
            .dispatch('traits/addAndUpdateTrait', {
              trait_id,
              value: name,
              layer_url: res.data.location,
              layer_index,
              type: layer_name,
            })
            .then(() => {
              ElNotification({
                type: 'success',
                title: 'SUCCESS',
              });
              trait.loading = false;
            });
        } else {
          trait.loading = false;
        }
      });
      return false;
    },
    updateTraitName({ type, trait_id }, newName) {
      if (newName) {
        this.$store
          .dispatch('traits/updateTraitName', {
            trait_id,
            type,
            value: newName,
          })
          .then(() => {
            ElNotification({
              type: 'success',
              title: 'SUCCESS',
            });
          });
      }
    },
    deleteTrait({ value, trait_id }) {
      ElMessageBox.confirm(
        `Are you sure you want to delete "${value}"? This action cannot be undone and the preview will need to be regenerated.`,
        'Are you sure?',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'el-button--danger danger',
          center: true,
          autofocus: false,
        }
      )
        .then(() => {
          this.$store
            .dispatch('traits/deleteTrait', { trait_id })
            .then(() => {
              ElNotification({
                type: 'success',
                title: 'SUCCESS',
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  & > .add {
    margin: 0 16px;
    &:hover {
      & > .el-button {
        border-color: #ff8aff;
        color: #ff8aff;
      }
    }
    :deep(.el-upload) {
      width: 100%;
      & > .el-button {
        width: 100%;
        height: 32px;
        background-color: transparent;
        border-color: #ff01ff;
        border-radius: 8px;
        border-style: dashed;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
        font-size: 12px;
        color: #ff01ff;
        transition: all 0.3s;
      }
    }
  }

  & > li {
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px 30px 6px 8px;
    margin: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
    color: #999999;
    &:hover {
      background-color: #fff;
      opacity: 1 !important;

      & > .action {
        & > .el-icon.delete {
          width: 30px;
        }
      }
    }

    & > .thumbnail {
      position: relative;
      margin-right: 6px;
      &:hover {
        & > .el-upload {
          & > .el-icon {
            display: flex;
          }
        }
      }
      :deep(.el-upload) {
        display: block;
        & > .el-icon {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.25);
          font-size: 12px;
          color: #fff;
        }

        & > .icon-loading {
          & > svg {
            animation: spinning 1s linear infinite;
          }
        }
        & > .icon-upload {
          display: none;
        }
      }
    }

    & > .info {
      flex: 1;
      display: flex;
      padding: 0 12px;
      text-align: center;
      font-size: 12px;

      & > .title {
        position: relative;
        display: block;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        :deep(.el-input) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          font-size: 12px;
          & > .el-input__wrapper {
            padding: 0;
            background-color: transparent;
            border-radius: 0;
            box-shadow: none;
            & > .el-input__inner {
              flex-grow: 0;
              height: 18px;
              text-align: center;
              line-height: 12px;
              color: #999999;
            }
          }
        }

        & > .placeholder {
          opacity: 0;
        }
      }

      & > .id {
        flex: 1;
        text-align: right;
      }
    }
    & > .action {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: stretch;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden;

      .el-icon {
        width: 30px;
        height: 34px;
        font-size: 16px;
      }

      & > .eye {
        cursor: pointer;
      }

      .el-icon.delete {
        width: 0;
        overflow: hidden;
        color: #fff;
        transition: all 0.3s;
      }
    }
  }

  & > .active {
    background-color: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    opacity: 1 !important;
  }
  & > .hidden {
    background-color: transparent !important;
    opacity: 0.3 !important;
    cursor: not-allowed;

    &:hover {
      opacity: 0.3 !important;
    }
  }
}
</style>

<style lang="less">
.wokspace_traits_popover {
  min-width: auto !important;
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
