<template>
  <el-button class="btn" @click="onShowDialog"
    >Gyroscope Setting</el-button
  >
  <el-dialog
    v-model="visible"
    :title="`${title} Setting`"
    width="850px"
    class="components_gyroscope_custom-dialog"
    append-to-body
    @close="onClose"
  >
    <div v-loading="loading" class="main">
      <slot name="main" />
      <div class="previewer-wrapper">
        <Previewer :type="type" :dataSource="model" />
      </div>
    </div>
    <template #footer>
      <el-button @click="onClose">Cancel</el-button>
      <el-button type="primary" @click="onSubmit">Save</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ElNotification } from 'element-plus';
import Previewer from '@/components/Gyroscope/components/Previewer';
export default {
  components: {
    Previewer,
  },
  props: {
    type: {
      type: [String, Number],
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    save: {
      type: Function,
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.save(this.model).then((res) => {
        this.loading = false;
        if (res.code === 0) {
          ElNotification({
            type: 'success',
            title: 'SUCCESS',
          });
          this.onClose();
        } else {
          ElNotification({
            type: 'error',
            title: 'ERROR',
          });
        }
      });
    },
    onShowDialog() {
      this.visible = true;
      this.$emit('open');
    },
    onClose() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less">
.components_gyroscope_custom-dialog {
  border-radius: 16px !important;
  overflow: hidden;
  font-family: PingFang SC;
  .el-dialog__header {
    height: 80px;
    padding: 0 20px;
    margin: 0;
    border-bottom: 1px solid rgb(220, 223, 230);
    .el-dialog__title {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 80px;
      color: #61666d;
    }
  }
  .el-dialog__headerbtn {
    top: 25px;
    right: 30px;
    width: auto;
    height: auto;
    & > .el-icon {
      font-size: 30px;
      color: #61666d;
    }
  }
  .el-dialog__body {
    padding: 0;
    .main {
      display: flex;
      align-items: stretch;
      height: calc(100vh - 145px - 145px - 80px - 63px);
      min-height: 250px;
      .list-wrapper {
        position: relative;
        width: 600px;
        height: 100%;
        overflow: auto;
      }
      .previewer-wrapper {
        position: relative;
        top: -1px;
        float: right;
        width: 250px;
        height: 100%;
      }
    }
  }
  .el-dialog__footer {
    border-top: 1px solid rgb(220, 223, 230);
    .el-button {
      display: inline-flex !important;
    }
  }
}
</style>

<style lang="less" scoped>
.title {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0;
  background-color: #f5f7fa;
  border-bottom: 1px solid rgb(220, 223, 230);

  & > span {
    flex-shrink: 0;
    width: 25%;
    text-align: center;
  }
}
.content {
  padding: 20px;
  .el-form {
    .field {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .field__label {
        width: 25%;
      }
      .el-form-item {
        width: 25%;
        margin: 0;
        :deep(.el-form-item__content) {
          justify-content: center;
          .el-input-number__decrease,
          .el-input-number__increase {
            bottom: 2px;
          }
        }
      }
    }
  }
}
</style>
