<template>
  <TopBar />
  <router-view></router-view>
</template>  
<script>
import TopBar from '@/components/TopBar';
export default {
  name: 'BaseLayout',
  components: {
    TopBar,
  },
  data () {
    return {};
  },
  created () {
    // watch 路由的参数，以便再次获取数据
    this.$watch(
      () => this.$route.params,
      () => {
        const collectionId = this.$route.params.collection_id;
        this.$store.commit('collection/UPDATE_ID', collectionId);
      },
      {
        immediate: true,
      }
    );
  },
};
</script>