import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getMetadataByNFTID } from '@/api';
// import { convertImage } from '@/utils';
import generateMetadata from './generateMetadata';

export default {
  methods: {
    exportCompressed(data) {
      const { collection_name } = this.info;
      const folderName =
        this.count < this.pageSize
          ? collection_name
          : `${collection_name}.part${this.page}`;
      if (this.currentIndex === 0) {
        this.zip = new JSZip();
        this.zipFolder = this.zip.folder(folderName);
      }
      return new Promise((resolve, reject) => {
        const asyncFunc = async () => {
          const { nft_id, raw_url } = data[this.currentIndex];
          const [err, res] = await getMetadataByNFTID({ nft_id });
          console.log(raw_url);
          if (!err) {
            const seriesNum = this.exportedCount;
            // const image = await convertImage(raw_url, 'base64');
            // const base64url = image.replace(
            //   /^data:image\/\w+;base64,/,
            //   ''
            // );
            // 生成 metadata
            const metadata = await generateMetadata(
              nft_id,
              seriesNum,
              this.info,
              res.data
            );
            await this.zipFolder.file(
              `${seriesNum}.json`,
              JSON.stringify(metadata, null, 2)
            );
            // await this.zipFolder.file(`${seriesNum}.jpg`, base64url, {
            //   base64: true,
            // });
            if (this.currentIndex === data.length - 1) {
              await this.zip
                .generateAsync({ type: 'blob' })
                .then((content) => {
                  saveAs(content, `${folderName}.zip`);
                });
            }
            await resolve();
          } else {
            reject(err);
          }
        };
        asyncFunc();
      });
    },
  },
};
