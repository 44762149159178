import {
  getThemeTraitsByName,
  uploadTraitsToCollection,
  deleteTrait,
  updateLayerName,
  renameTrait,
} from '@/api';
import { blobUrl } from '@/utils';
import { EMPTY_ITEM_NAME } from '@/assets/constant';
export default {
  namespaced: true,
  state: {
    list: [],
    combination: new Map(),
  },
  mutations: {
    UPDATE_LIST(state, list) {
      state.list = list;
    },
    UPDATE_COMBINATION(state, combination) {
      state.combination = combination;
    },
  },
  getters: {
    layers(state) {
      const { list } = state;
      const newData = new Map();
      const sortedList = list.sort(
        (pre, cur) => pre.layer_index - cur.layer_index
      );
      for (const item of sortedList) {
        item.blobUrl = blobUrl(item.layer_url);
        if (newData.has(item.type)) {
          const arr = newData.get(item.type);
          // 空层放在第一项
          if (item.value === EMPTY_ITEM_NAME) {
            arr.unshift(item);
          } else {
            arr.push(item);
          }
          newData.set(item.type, arr);
        } else {
          newData.set(item.type, [item]);
        }
      }
      return newData;
    },
  },
  actions: {
    getTraits({ rootState, commit }) {
      const { collection, themes } = rootState;
      const { id } = collection;
      const { current } = themes;
      const { theme_name } = current || {};

      commit('UPDATE_LIST', []);

      return new Promise((resolve, reject) => {
        if (theme_name) {
          getThemeTraitsByName(id, theme_name).then(([err, res]) => {
            if (err) {
              reject(err);
            } else {
              const traits = res.data.traits;
              const types = [
                ...new Set(traits.map((item) => item.type)),
              ];
              const emptyTraits = types.map((item) => {
                const layer_index = traits.find(
                  (childItem) => childItem.type === item
                )?.layer_index;
                return {
                  trait_id: `blank-${layer_index}`,
                  type: item,
                  layer_index,
                  value: EMPTY_ITEM_NAME,
                  disabled: true,
                  hidden: true,
                };
              });
              commit('UPDATE_LIST', [
                ...traits.map((item) => ({
                  ...item,
                  editable: true,
                })),
                ...emptyTraits,
              ]);
              resolve(res.data);
            }
          });
        }
      });
    },
    addAndUpdateTrait({ rootState, state, commit }, payload) {
      const { collection, themes } = rootState;
      const { id } = collection;
      const { current } = themes;
      const { theme_name } = current || {};
      const { list } = state;
      const { trait_id } = payload;
      return new Promise((resolve, reject) => {
        uploadTraitsToCollection(id, { ...payload, theme_name }).then(
          ([err, res]) => {
            if (err) {
              reject(err);
            } else {
              if (trait_id) {
                const index = list.findIndex(
                  (item) => item.trait_id === trait_id
                );
                list.splice(index, 1, res.data);
              } else {
                list.unshift(res.data);
              }
              commit('UPDATE_LIST', list);
              resolve(res.data);
            }
          }
        );
      });
    },
    updateTraitName({ rootState, state, commit }, payload) {
      const { collection } = rootState;
      const { id } = collection;
      const { list } = state;
      const { trait_id, type, value } = payload;
      return new Promise((resolve, reject) => {
        renameTrait(id, trait_id, { type, value }).then(
          ([err, res]) => {
            if (err) {
              reject(err);
            } else {
              const index = list.findIndex(
                (item) => item.trait_id === trait_id
              );
              list.splice(index, 1, res.data);
              commit('UPDATE_LIST', list);
              resolve(res.data);
            }
          }
        );
      });
    },
    deleteTrait({ rootState, state, commit }, payload) {
      const { collection } = rootState;
      const { id } = collection;
      const { list } = state;
      const { trait_id } = payload;
      return new Promise((resolve, reject) => {
        deleteTrait(id, trait_id).then(([err, res]) => {
          if (err) {
            reject(err);
          } else {
            const index = list.findIndex(
              (item) => item.trait_id === trait_id
            );
            list.splice(index, 1);
            commit('UPDATE_LIST', list);
            resolve(res.data);
          }
        });
      });
    },
    updateLayerName({ rootState, state, commit }, payload) {
      const { collection, themes } = rootState;
      const { id } = collection;
      const { current } = themes;
      const { theme_name } = current || {};
      const { list } = state;
      const { name, newName } = payload;
      return new Promise((resolve, reject) => {
        updateLayerName(id, { ...payload, theme_name }).then(
          ([err, res]) => {
            if (err) {
              reject(err);
            } else {
              const newList = list.map((item) => ({
                ...item,
                type: item.type === name ? newName : item.type,
              }));
              commit('UPDATE_LIST', newList);
              resolve(res.data);
            }
          }
        );
      });
    },
    selectTrait({ state, commit }, trait) {
      const { combination } = state;
      const { trait_id, hidden } = trait;
      if (hidden || combination.has(trait_id)) {
        combination.delete(trait_id);
      } else {
        combination.set(trait_id, trait);
      }
      commit('UPDATE_COMBINATION', combination);
    },
  },
};
