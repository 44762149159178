<template>
  <div class="page--full">
    <div class="page__top">
      <Themes @select="getTraitsByTheme" />
    </div>
    <div
      :class="{
        'page__left--collapsed': collapsed,
      }"
      class="page__left"
    >
      <div class="main">
        <div class="toolbar">
          <el-badge
            :value="rules.filter((item) => item.active).length"
            :hidden="!rules.filter((item) => item.active).length"
            style="margin-right: 12px"
          >
            <el-button @click="traitRules.dialog.visible = true">
              <el-icon><Connection /></el-icon>
              <span>Rules</span>
            </el-button>
          </el-badge>
          <el-button style="flex: 1" @click="generate">
            <el-icon>
              <Refresh />
            </el-icon>
            <span>Generate</span>
          </el-button>
        </div>
        <div class="content">
          <Traits :loading="loading.traits" />
        </div>
      </div>
      <div class="collapse" @click="collapse">
        <el-icon>
          <DArrowLeft />
        </el-icon>
      </div>
    </div>
    <div class="page__right">
      <div ref="scrollable" class="main">
        <!-- @scroll="scrollLoad" -->
        <div class="toolbar">
          <div class="toolbar__left">
            <span>{{ count }} results</span>
          </div>
          <div class="toolbar__right">
            <component
              v-if="isGyroscope"
              v-bind:is="
                {
                  7: 'GyroscopeTranslateConfig',
                  8: 'GyroscopeOpacityConfig',
                }[collectionInfo.material_type]
              "
              :layers="layerList"
              :save="saveGyroscopeConfig"
            />
          </div>
        </div>
        <div class="content">
          <PresetTokens
            :loading="loading.presetTokens"
            :pagination="pagination"
          />
          <BigPagination
            :current-page="pagination.page"
            :page-size="pagination.pageSize"
            layout="jumper, prev, pager, next"
            :total="count"
            @current-change="onPage"
          /> 
        </div>
      </div>
    </div>
  </div>
  <CloudTokens />
  <el-dialog
    v-model="traitRules.dialog.visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="50vw"
    destroy-on-close
  >
    <TraitRules @close="traitRules.dialog.visible = false" />
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  Refresh,
  Connection,
  DArrowLeft,
} from '@element-plus/icons-vue';
import { debounce } from 'lodash';
import GyroscopeTranslateConfig from '@/components/Gyroscope/Translate/Config';
import GyroscopeOpacityConfig from '@/components/Gyroscope/Opacity/Config';
import Themes from './components/Themes';
import Traits from './components/Traits';
import PresetTokens from './components/PresetTokens';
import CloudTokens from './components/CloudTokens';
import TraitRules from './components/TraitRules';
import ElPagination from '@/components/pagination/index.mjs';
export default {
  components: {
    BigPagination: ElPagination,
    Refresh,
    Connection,
    DArrowLeft,
    GyroscopeTranslateConfig,
    GyroscopeOpacityConfig,
    Themes,
    Traits,
    PresetTokens,
    CloudTokens,
    TraitRules,
  },
  setup() {
    const scrollable = ref();
    return {
      scrollable,
    };
  },
  data() {
    return {
      collapsed: false,
      loading: {
        traits: false,
        presetTokens: false,
      },
      pagination: {
        page: 1,
        pageSize: 0,
        pagerCount: 0,
      },
      traitRules: {
        dialog: {
          visible: false,
        },
      },
    };
  },
  computed: {
    ...mapState('collection', {
      collectionId: ({ id }) => id,
      collectionInfo: ({ info }) => info,
    }),
    ...mapState('themes', {
      currentTheme: ({ current }) => current,
    }),
    ...mapGetters('traits', ['layers']),
    ...mapState('presetTokens', {
      presetTokens: ({ list }) => list,
      count: ({ count }) => count,
    }),
    ...mapState('traitRules', {
      rules: ({ list }) => list,
    }),
    layerList() {
      const arr = [...this.layers.values()];
      return arr.map((item) => item[1]);
    },
    isGyroscope() {
      return [7, 8].includes(this.collectionInfo.material_type);
    },
    debounceResize() {
      return debounce(this.initLayout, 100);
    },
  },
  watch: {
    'pagination.pageSize': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.generateTokens();
      }
    },
  },
  mounted() {
    this.initLayout();
    this.initRules();
    this.getCollectionInfo();
    window.addEventListener('resize', this.debounceResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debounceResize);
  },
  methods: {
    initLayout() {
      const tokenW = 220;
      const tokenH = 252;
      const pagerH = 72;
      const pagerNumberW = 50;
      const { clientWidth, clientHeight } =
        this.$el.ownerDocument.querySelector(
          '.page__right>.main>.content'
        );
      const availableW = clientWidth - 20; // list padding
      const availableH = clientHeight - pagerH - 20; // list padding
      const availableCols = Math.floor(availableW / tokenW);
      const availableRows = Math.floor(availableH / tokenH);
      this.pagination.pageSize = availableCols * availableRows;
      this.pagination.pagerCount = Math.floor(
        (clientWidth - 350) / pagerNumberW
      );
    },
    initRules() {
      this.$store.dispatch('traitRules/getRules');
    },
    getTraitsByTheme() {
      this.loading.traits = true;
      this.loading.presetTokens = true;
      this.$store.commit('traits/UPDATE_LIST', []);
      this.$store.commit('presetTokens/UPDATE_LIST', []);
      this.$store.dispatch('traits/getTraits').then(() => {
        this.loading.traits = false;
        this.generate();
      });
    },
    getCollectionInfo() {
      this.$store.dispatch('collection/getInfo').then(() => {
        if (this.isGyroscope) {
          this.getGyroscopeConfig();
        }
      });
    },
    generate() {
      this.pagination.page = 1;
      this.loading.presetTokens = true;
      // this.$refs.scrollable.scrollTop = 0;
      // this.$store.dispatch('presetTokens/calculateCount');
      this.generateTokens();
    },
    // scrollLoad({ target }) {
    //   const { clientHeight, scrollHeight, scrollTop } = target;
    //   if (scrollTop + clientHeight === scrollHeight) {
    //     if (this.presetTokens.length < this.count) {
    //       this.pagination.page++;
    //       this.generateTokens();
    //     }
    //   }
    // },
    generateTokens() {
      const { page, pageSize } = this.pagination;
      this.$store
        .dispatch('presetTokens/generateTokens', { page, pageSize })
        .then(() => {
          console.log('presetTokens/generateTokens then');
          this.loading.presetTokens = false;
        });
    },
    saveGyroscopeConfig(payload) {
      return this.$store.dispatch('gyroscope/fetchAsyncPostConfig', {
        collectionId: this.collectionId,
        data: payload,
      });
    },
    getGyroscopeConfig() {
      this.$store.dispatch('gyroscope/fetchAsyncGetConfig', {
        collectionId: this.collectionId,
      });
    },
    onPage(page) {
      console.log('onPage',page);
      this.pagination.page = page;
      this.generateTokens();
    },
    collapse() {
      this.collapsed = !this.collapsed;
      setTimeout(() => {
        this.initLayout();
      }, 300);
    },
  },
};
</script>

<style lang="less" scoped>
@import url('~@/assets/css/function.less');

.page--full {
  display: grid;
  grid-template-columns: min-content;
  grid-template-rows: max-content;
  width: 100%;
  height: calc(100vh - 80px);
  overflow: hidden;
  background-color: #f5f7fa;

  .page__top {
    grid-column: 1 / span 2;
    border-bottom: 1px solid #000;
  }

  .page__left,
  .page__right {
    height: 100%;
    min-height: 0;
  }

  .page__left {
    position: relative;
    width: 350px;
    padding-right: 18px;
    transition: all 0.3s;

    & > .main {
      position: relative;

      & > .toolbar {
        position: sticky;
        top: 0;
        z-index: 100;
        display: flex;
      }
    }

    & > .collapse {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      background-color: #fff;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      cursor: pointer;
    }
  }

  & > .page__left--collapsed {
    width: 0;

    & > .collapse {
      & > .el-icon {
        transform: rotate(180deg);
      }
    }
  }

  .page__right {
    & > .main {
      display: flex;
      flex-direction: column;

      & > .toolbar {
        justify-content: space-between;

        & > .toolbar__left {
          & > span {
            color: @font-color--light;
          }
        }
      }

      & > .content {
        position: relative;
        flex: 1;
        padding-bottom: 72px;
        overflow: hidden;

        :deep(.el-pagination) {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          display: flex;
          justify-content: center;
          height: 72px;
          & > .el-pager {
            & > li {
              background-color: transparent;
            }
          }

          & > button {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.page__left > .main,
.page__right > .main {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  content-visibility: auto;

  & > .toolbar {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 14px;
    background-color: #fff;
    border-bottom: 1px solid #000;
  }
}
</style>
