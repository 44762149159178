import { getCollection } from '@/api';
export default {
  namespaced: true,
  state: {
    id: '',
    info: {}
  },
  mutations: {
    UPDATE_ID (state, id) {
      state.id = id;
    },
    UPDATE_INFO (state, info) {
      state.info = info;
    }
  },
  actions: {
    getInfo ({ state, commit }) {
      const { id } = state;
      return new Promise((resolve, reject) => {
        getCollection(id).then(([err, res]) => {
          if (err) {
            reject(err);
          } else {
            commit('UPDATE_INFO', res.data);
            resolve(res);
          }
        });
      });
    }
  }
};