export const generateGyroscopeData = (type, source) => {
  let attributes;
  const filteredSource = source.filter(item => item.file_url);
  switch (type) {
  case 7:
  case 'translate':
    attributes = filteredSource.map((item) => {
      const { file_url, scale = 1, translateX = 0, translateY = 0 } = item;
      return {
        display_type: "physical_orientation",
        trait_count: 1,
        trait_type: "素材图层",
        value: "素材名称",
        contents: [
          {
            file_url,
            scale,
            physical_orientation: [
              {
                type: 'gamma',
                angle: [-45, 0],
                animations: [
                  {
                    type: 'offsetX',
                    value: [translateX / -100, 0],
                    bezier: 'cubic-bezier#0,0,1,1',
                  },
                ],
              },
              {
                type: 'gamma',
                angle: [0, 45],
                animations: [
                  {
                    type: 'offsetX',
                    value: [0, translateX / 100],
                    bezier: 'cubic-bezier#0,0,1,1',
                  },
                ],
              },
              {
                type: 'beta',
                angle: [-45, 0],
                animations: [
                  {
                    type: 'offsetY',
                    value: [translateY / -100, 0],
                    bezier: 'cubic-bezier#0,0,1,1',
                  },
                ],
              },
              {
                type: 'beta',
                angle: [0, 45],
                animations: [
                  {
                    type: 'offsetY',
                    value: [0, translateY / 100],
                    bezier: 'cubic-bezier#0,0,1,1',
                  },
                ],
              },
            ],
          },
        ],
      };
    });
    break;
  case 8:
  case 'opacity':
    attributes = filteredSource.map((item) => {
      const { file_url, opacity = [] } = item;
      return {
        contents: [
          {
            file_url,
            scale: 1,
            physical_orientation: [
              {
                type: 'gamma',
                angle: [opacity[0]?.[0], opacity[1]?.[0]],
                animations: [
                  {
                    type: 'opacity',
                    value: [opacity[0]?.[1], opacity[1]?.[1]],
                    bezier: 'cubic-bezier#0,0,1,1',
                  },
                ],
              },
              {
                type: 'gamma',
                angle: [opacity[1]?.[0], opacity[2]?.[0]],
                animations: [
                  {
                    type: 'opacity',
                    value: [opacity[1]?.[1], opacity[2]?.[1]],
                    bezier: 'cubic-bezier#0,0,1,1',
                  },
                ],
              },
            ],
          },
        ],
      };
    });
    break;
  default:
    attributes = undefined;
    break;
  }
  return {
    attributes,
  };
};
  