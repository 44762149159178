<template>
  <el-dialog
    :model-value="visible"
    top="20vh"
    width="350px"
    append-to-body
    @close="$emit('close')"
  >
    <div class="wrapper">
      <img src="@/assets/images/metamask.png" alt />
      <h3>MetaMask is not installed</h3>
      <p>The MetaMask extension is not installed in your browser</p>
      <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" 
         target="_blank">
        <el-button type="primary">INSTALL</el-button>
      </a>
    </div>
  </el-dialog>
</template>
  
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    padding: 0 18px;
    text-align: center;

    & > img {
        display: block;
        margin-bottom: 24px;
    }

    & > h3 {
        margin-bottom: 14px;
        color: @black;
    }

    & > p {
        margin-bottom: 24px;
        font-size: 14px;
        color: @font-color--light;
    }

    & > a {
        text-decoration: none;
    }
}
</style>