import { getNFTList, saveNFT, cloudNFT, batchDeleteNFT } from '@/api';

export default {
  namespaced: true,
  state: {
    list: [],
    count: 0,
  },
  mutations: {
    UPDATE_LIST(state, list) {
      state.list = list;
    },
    UPDATE_COUNT(state, count) {
      state.count = count;
    },
  },
  actions: {
    getList({ rootState, commit }, payload = {}) {
      const { collection } = rootState;
      const { id } = collection;
      return new Promise((resolve, reject) => {
        getNFTList({
          collection_id: id,
          page_num: 1,
          page_size: 100,
          ...payload
        }).then(([err, res]) => {
          if (err) {
            reject(err);
          } else {
            commit('UPDATE_LIST', res.data.list);
            if (!payload.theme_name) {
              commit('UPDATE_COUNT', res.data.total);
            }
            resolve(res);
          }
        });
      });
    },
    addToken({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        const { collection, themes } = rootState;
        const { id } = collection;
        const { current: { theme_id } } = themes;
        saveNFT({
          collection_id: Number(id),
          theme_id: Number(theme_id),
          traits: payload
        }).then(([err, data]) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    },
    deleteToken(context, payload) {
      return new Promise((resolve, reject) => {
        const { nft_id } = payload;
        batchDeleteNFT([nft_id]).then(([err]) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        });
      });
    },
    saveAllToCloud({ rootState, rootGetters }, payload = {}) {
      return new Promise((resolve, reject) => {
        console.log('rootState', rootState);
        console.log('rootGetters', rootGetters);
        const { collection, themes, traits, traitRules } = rootState;
        const { combination } = traits;
        const { 'traits/layers': layers } = rootGetters;
        const newLayers = [...layers].map(([, traits]) => {
          const visibleTraits = traits.filter((item) => !item.hidden);
          const selectedTraits = visibleTraits.filter((item) =>
            combination.has(item.trait_id)
          );
          return selectedTraits.length
            ? selectedTraits
            : visibleTraits;
        });
        console.log(payload);
        const rules = traitRules.list.filter((item) => item.active);
        console.log(rules);

        const trait_ids = [];
        for (const layer of newLayers) {
          trait_ids.push(...layer.map(({ trait_id }) => trait_id))
        }
        const { current: { theme_id } } = themes;
        if (trait_ids.length) {
          cloudNFT({
            collection_id: collection.id,
            theme_id,
            traits: trait_ids,
          }).then(([err, data]) => {
            console.log('cloudNFT', data);

            if (err) {
              reject(err);
            } else {
              resolve(data?.data);
            }
          });
        } else {
          reject('no traits');
        }
      });
    },
  }
};