<template>
  <el-button :size="size" class="button" @click="start">
    <div v-if="started" class="progress">
      <el-progress
        :text-inside="true"
        :stroke-width="40"
        :percentage="percentage"
        :format="() => ''"
        status="success"
      />
      <div class="inner">
        <div
          v-if="controllable"
          class="controller"
          @click.stop="pause"
        >
          <el-icon v-if="paused">
            <CaretRight />
          </el-icon>
          <i v-else class="iconfont icon-paused"></i>
          <el-icon class="filled" @click.stop="$emit('cancel')">
            <CircleCloseFilled />
          </el-icon>
        </div>
        <span class="label">
          {{ `${progress} / ${total}` }}
          </span>
      </div>
    </div>
    <span>{{ label }}</span>
  </el-button>
</template>

<script>
import {
  CaretRight,
  CircleCloseFilled,
} from '@element-plus/icons-vue';
export default {
  components: {
    CaretRight,
    CircleCloseFilled,
  },
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
    },
    progress: {
      type: Number,
      required: true,
      default: 0,
    },
    total: {
      type: Number,
      required: true,
      default: 1,
    },
    controllable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paused: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    percentage() {
      return Math.floor((this.progress / (this.total || 1)) * 100);
    },
    // started() {
    //   return (
    //     (this.paused || this.loading) && this.progress < this.total
    //   );
    // },
    started() {
      return (this.paused || this.loading);
    },
  },
  methods: {
    start() {
      this.$emit('start');
    },
    pause() {
      this.$emit('pause');
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  position: relative;
  width: 200px;
  overflow: hidden;
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    & > .el-progress {
      height: 100%;
      :deep(.el-progress-bar) {
        .el-progress-bar__outer {
          background-color: #ffcdff;
          border-radius: 0;
          .el-progress-bar__inner {
            position: static;
            background-color: @primary;
            border-radius: 0;
            .el-progress-bar__innerText {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
    & > .inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      & > .controller {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        font-style: normal;
        color: @white;
        cursor: pointer;
        & > .el-icon {
          font-size: 28px;
        }
        & > .iconfont {
          width: 28px;
        }
        & > .el-icon.filled {
          color: @white;
        }
      }
      & > .label {
        font-size: 14px;
      }
    }
  }
}
</style>
