import { getMetadataByNFTID } from '@/api';
// import { convertImage } from '@/utils';
import generateMetadata from './generateMetadata';

export default {
  methods: {
    async exportUncompressed(data) {
      console.log('exportUncompressed');
      return new Promise((resolve, reject) => {
        const asyncFunc = async () => {
          const { nft_id, raw_url } = data[this.currentIndex];
          const [err, res] = await getMetadataByNFTID({ nft_id });

          console.log(raw_url);
          if (!err) {
            const seriesNum = this.exportedCount;
            // const image = await convertImage(raw_url, 'blob');
            // const imageHandler =
            //   await this.newDirHandle.getFileHandle(
            //     `${seriesNum}.png`,
            //     {
            //       create: true,
            //     }
            //   );
            console.log('jsonHandler');
            const jsonHandler = await this.newDirHandle.getFileHandle(
              `${seriesNum}.json`,
              {
                create: true,
              }
            );
            // create a FileSystemWritableFileStream to write to
            // const imageWritableStream =
            //   await imageHandler.createWritable();
            console.log('jsonWritableStream');
            const jsonWritableStream =
              await jsonHandler.createWritable();

            // 生成 metadata
            console.log('generateMetadata');
            const metadata = await generateMetadata(
              nft_id,
              seriesNum,
              this.info,
              res.data
            );

            // write our file
            // await imageWritableStream.write(image);
            console.log('jsonWritableStream.write');
            await jsonWritableStream.write(
              JSON.stringify(metadata, null, 2)
            );

            // close the file and write the contents to disk.
            // await imageWritableStream.close();
            console.log('jsonWritableStream.close');
            console.time("jsonWritableStream.close" + seriesNum);
            await jsonWritableStream.close();
            console.timeEnd("jsonWritableStream.close" + seriesNum);
            console.log('resolve');
            await resolve();
          } else {
            reject(err);
          }
          console.log(res);
        };
        asyncFunc();
      });
    },
  },
};
