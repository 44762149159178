<template>
  <ButtonWithProgress :disabled="disabled || loading" :paused="paused" :loading="loading" :progress="currentIndex"
    :total="exportedCount" :controllable="true" label="Download" size="large" @start="readyExport" @pause="pauseExport"
    @cancel="resetExport" />
</template>

<script>
import { mapState } from 'vuex';
import { ElNotification, ElMessageBox } from 'element-plus';
import { downloadNFT } from '@/api';
import ButtonWithProgress from '@/components/ButtonWithProgress';
import exportCompressed from './exportCompressed';
import exportUncompressed from './exportUncompressed';
export default {
  mixins: [exportCompressed, exportUncompressed],
  components: {
    ButtonWithProgress,
  },
  props: {
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      paused: false,
      exportedCount: 0,
      currentIndex: 0,
      timer: null,
    };
  },
  computed: {
    ...mapState('collection', ['info']),
    ...mapState('themes', ['current']),
    ...mapState('cloudTokens', ['count']),
    isFileSystemAccess() {
      // https://developer.mozilla.org/en-US/docs/Web/API/window/showDirectoryPicker
      // This feature is available only in secure contexts (HTTPS)
      // compatibility：Chrome 86｜Edge 86｜Opera 72
      // return !!window.showDirectoryPicker;
      return false;
    },
  },
  methods: {
    readyExport() {
      if (this.count) {
        const eachCost = 0.2; // 导出1个需要0.5分钟
        const cost = Math.floor(this.count * eachCost);
        const msg = `Total ${this.count
          } tokens. Your export will take over ${cost > 30 ? 30 : cost
          } minutes.`;
        //
        ElMessageBox.confirm(msg, 'Info', {
          type: 'info',
        })
          .then(async () => {
            await this.resetExport();
            this.loading = true;
            await this.startExport();
          })
          .catch(() => { });
      }
    },
    pauseExport() {
      this.paused = !this.paused;

      if (this.paused) {
        clearTimeout(this.timer);
      } else {
        this.startExport();
      }
    },
    resetExport() {
      this.loading = false;
      this.paused = false;
      this.currentIndex = 0;
      this.exportedCount = 0;
    },
    async startExport() {
      try {
        console.log('startExport');
        clearTimeout(this.timer);
        const { collection_id } = this.info;
        const [err, { data: res }] = await downloadNFT({
          collection_id,
        });
        if (err) {
          throw err;
        }
        console.log(res);
        this.currentIndex = Number(res.data.current);
        this.exportedCount = Number(res.data.count);
        // this.currentIndex++;
        if (this.currentIndex < this.exportedCount && !res.url) {
          this.timer = setTimeout(() => {
            console.log('setTimeout');
            this.startExport();
          }, 5000);
        } else {
          console.log(res.url);
          window.open(res.url)
          await this.resetExport();
        }
      } catch (error) {
        ElNotification({
          type: 'error',
          title: 'NETWORK ERROR',
        });
        this.paused = true;
      }
    },
  },
};
</script>
