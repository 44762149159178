<template>
  <el-skeleton v-if="loading" :rows="30" class="skeleton" animated />
  <div v-else-if="layers.size" class="layer-list">
    <el-collapse
      v-for="[layer_name, traits] of layers"
      :key="layer_name"
      class="layer"
    >
      <el-collapse-item>
        <template #title>
          <div class="layer__header">
            <div class="title">
              <el-input
                :model-value="
                  currentLayer.name === layer_name
                    ? currentLayer.model.name
                    : layer_name
                "
                :placeholder="layer_name"
                @click.stop
                @keypress.stop
                @focus="
                  (currentLayer.name = layer_name),
                    (currentLayer.model.name = layer_name)
                "
                @input="(val) => (currentLayer.model.name = val)"
                @blur="currentLayer.model.name = layer_name"
                @keyup.esc="currentLayer.model.name = layer_name"
                @keyup.enter="
                  editLayerName(layer_name, currentLayer.model.name)
                "
              ></el-input>
              <span class="placeholder">{{
                currentLayer.name === layer_name
                  ? currentLayer.model.name || layer_name
                  : layer_name
              }}</span>
            </div>
            <div class="count">
              <el-tag
                v-if="count(traits)"
                size="small"
                type="success"
                effect="pain"
                closable
                @close="clearCombinationByLayerName(layer_name)"
              >
                {{ count(traits) }} selected
              </el-tag>
              <span>
                {{ traits.filter((item) => !item.hidden).length }}
                traits
              </span>
            </div>
          </div>
        </template>
        <TraitList
          :layer="{ layer_name, layer_index: traits[0].layer_index }"
          :list="traits"
        />
      </el-collapse-item>
    </el-collapse>
  </div>
  <el-empty v-else :image-size="200" />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ElNotification } from 'element-plus';
import TraitList from './components/TraitList';
export default {
  components: {
    TraitList,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      currentLayer: {
        name: null,
        model: {
          name: null,
        },
      },
    };
  },
  computed: {
    ...mapState('traits', ['list', 'combination']),
    ...mapGetters('traits', ['layers']),
    count() {
      return (list) => {
        const count = list.filter((item) =>
          this.combination.has(item.trait_id)
        ).length;
        return count === list.length ? '' : count;
      };
    },
  },
  methods: {
    editLayerName(name, newName) {
      if (newName) {
        this.$store
          .dispatch('traits/updateLayerName', { name, newName })
          .then(() => {
            ElNotification({
              type: 'success',
              title: 'SUCCESS',
            });
          });
      }
    },
    clearCombinationByLayerName(layer_name) {
      this.combination.forEach((value, key) => {
        if (value.type === layer_name) {
          this.combination.delete(key);
        }
      });
      this.$store.commit(
        'traits/UPDATE_COMBINATION',
        this.combination
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/css/function.less';
.layer-list {
  :deep(.el-collapse) {
    border: none;
    .el-collapse-item {
      &:last-child {
        margin-bottom: 0;
      }
      .el-collapse-item__header {
        height: 36px;
        padding: 0 16px;
        border-bottom: none;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: @font-color--light;

        & > .el-collapse-item__arrow {
          display: none;
        }

        & > .layer__header {
          display: flex;
          align-items: center;
          width: 100%;
          & > .title {
            position: relative;
            & > .el-input {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              width: 100%;
              height: 100%;
              font-size: 14px;
              & > .el-input__wrapper {
                padding: 0;
                background-color: transparent;
                border-radius: 0;
                box-shadow: none;
                & > .el-input__inner {
                  height: 14px;
                  line-height: 14px;
                  color: @font-color;
                }
              }
            }

            & > .placeholder {
              opacity: 0;
            }
          }
          & > .count {
            flex: 1;
            text-align: right;
            font-size: 12px;
            color: @font-color--light;
            white-space: nowrap;

            & > .el-tag {
              margin-right: 6px;
            }
          }
        }
      }
      .el-collapse-item__wrap {
        border-top: 1px solid #000;
        border-bottom: none;
        background-color: #f5f7fa;
        overflow: visible;
      }
      .el-collapse-item__content {
        padding: 14px 0;

        .trait {
          .transparent_background(5);
        }
      }
    }
  }
  .layer {
    border-bottom: 1px solid #000;
  }
}
.skeleton {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 20px;
  overflow: hidden;
}
</style>
