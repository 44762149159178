<template>
  <div
    :style="{
      height: domHeight,
    }"
    class="switch"
    @click="showDrawer"
  >
    <div class="count_button">
      <el-icon>
        <MostlyCloudy />
      </el-icon>
      <h3>{{ count }}</h3>
      <!-- <div
        :class="{
          animation: showAnimation,
        }"
      ></div> -->
    </div>
    <div
      v-loading="!formattedList.length && loading"
      class="body"
      @click.stop
      @scroll="scrollLoad"
    >
      <div class="form">
        <el-select
          v-model="form.model.theme"
          placeholder="全部"
          clearable
          @change="query"
        >
          <el-option
            v-for="item in themes"
            :key="item.theme_id"
            :value="item.theme_name"
            :label="item.theme_name"
          />
        </el-select>
        <el-button v-if="generatingCount" @click="refresh">
          <el-icon><Refresh /></el-icon>
        </el-button>
      </div>
      <div class="list">
        <ul v-if="formattedList.length">
          <li
            v-for="(item, index) of formattedList"
            :key="item.nft_id"
            @click="showTokenDetail(index)"
          >
            <div class="token-wrapper">
              <Token :rawUrl="item.raw_url" :traits="item.traits" />
              <div
                v-if="[1, 6].includes(item.nft_status)"
                class="generating-mask"
              >
                <span>GENERATING</span>
              </div>
            </div>
            <div class="desc">#{{ item.nft_id }}</div>
            <div class="controls">
              <el-icon
                class="delete"
                @click.stop="deleteToken(item, index)"
              >
                <Delete />
              </el-icon>
            </div>
          </li>
        </ul>
        <el-empty v-else :image-size="200" />
      </div>
    </div>
    <div
      v-if="collectionInfo.material_type !== 8"
      class="fixed_buttons"
      @click.stop
    >
      <!-- <el-button class="btn--primary">
        <el-icon>
          <Promotion />
        </el-icon>Deploy
      </el-button> -->
      <el-tooltip
        :disabled="!generatingCount"
        placement="top"
        effect="light"
        content="There are tokens being generated and cannot be downloaded"
      >
        <ExportMetadata
          :disabled="!!generatingCount"
          class="btn--primary"
        />
      </el-tooltip>
    </div>
  </div>
  <div
    v-if="drawer.visible"
    class="mask"
    @click="drawer.visible = false"
  ></div>
  <el-dialog
    :model-value="dialog.visible"
    :append-to-body="true"
    class="components_dialog_token_detail"
    width="1000px"
    destroy-on-close
    @close="dialog.visible = false"
  >
    <TokenDetail
      :rawUrl="formattedList[dialog.token_index].raw_url"
      :traits="formattedList[dialog.token_index].traits"
      :readonly="true"
    />
    <div class="corner_btn">
      <el-icon
        class="star"
        @click="
          deleteToken(
            formattedList[dialog.token_index],
            dialog.token_index
          )
        "
      >
        <Delete />
      </el-icon>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { ElMessageBox, ElNotification } from 'element-plus';
import {
  MostlyCloudy,
  Delete,
  Refresh,
} from '@element-plus/icons-vue'; // , Promotion
import Token from '@/components/Token';
import TokenDetail from '@/components/TokenDetail';
import ExportMetadata from './components/ExportMetadata';
import { generateManually } from '@/api';
import { aliOSSImage } from '@/utils';
export default {
  components: {
    MostlyCloudy,
    Refresh,
    // Promotion,
    Delete,
    Token,
    TokenDetail,
    ExportMetadata,
  },
  data() {
    return {
      loading: false,
      // showAnimation: false,
      height: '14px',
      list: [],
      total_count: 0,
      generatingCount: 0,
      form: {
        model: {
          theme: null,
        },
      },
      drawer: {
        visible: false,
      },
      dialog: {
        visible: false,
        token_index: null,
      },
      pagination: {
        page: 1,
        size: 50,
      },
    };
  },
  computed: {
    ...mapState('collection', {
      collectionId: ({ id }) => id,
      collectionInfo: ({ info }) => info,
    }),
    ...mapState('themes', {
      themes: ({ list }) => list,
    }),
    ...mapState('cloudTokens', ['count']),
    formattedList() {
      return this.list.map((item) => ({
        ...item,
        traits: item.traits.map((childItem) => ({
          ...childItem,
          layer_url: aliOSSImage(childItem.layer_url, 'w400'),
        })),
      }));
    },
    domHeight() {
      return this.drawer.visible ? '90vh' : '14px';
    },
  },
  // watch: {
  //   count: function (newVal, oldVal) {
  //     clearTimeout(this.timer);
  //     if (newVal - oldVal === 1) {
  //       this.showAnimation = true;
  //       this.timer = setTimeout(() => {
  //         this.showAnimation = false;
  //       }, 500);
  //     }
  //   },
  // },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      this.reset();
      this.getCloudTokens();
    },
    getCloudTokens() {
      this.loading = true;
      const { page, size } = this.pagination;
      return this.$store
        .dispatch('cloudTokens/getList', {
          theme_name: this.form.model.theme,
          page_num: page,
          page_size: size,
        })
        .then((res) => {
          this.loading = false;
          this.total_count = res.data.total_count;
          this.list.push(...res.data.list);
        });
    },
    refresh() {
      this.reset();
      Promise.all([...this.manualGenerate()]).then((res) => {
        const result = res.map((item) => item?.[1]?.data);
        this.getCloudTokens();
        if (!result.includes('ok')) {
          this.generatingCount = 0;
        }
      });
    },
    manualGenerate() {
      return [1, 6].map((item) =>
        generateManually({
          collectionId: this.collectionId,
          nft_status: item,
        })
      );
    },
    getCloudTokensInGenerating() {
      this.generatingCount = 0;
      return [1, 6].map((item) =>
        this.$store
          .dispatch('cloudTokens/getList', {
            page_num: 1,
            page_size: 1,
            nft_status: item,
          })
          .then((res) => {
            this.generatingCount =
              this.generatingCount + res.data.total_count;
          })
      );
    },
    showDrawer() {
      this.drawer.visible = !this.drawer.visible;
      if (this.drawer.visible) {
        this.reset();
        Promise.all(this.getCloudTokensInGenerating()).then(() => {
          this.getCloudTokens();
        });
      }
    },
    showTokenDetail(index) {
      this.dialog.visible = true;
      this.dialog.token_index = index;
    },
    deleteToken(token, index) {
      const { nft_id } = token;
      ElMessageBox.confirm(
        `Are you sure you want to archive "#${nft_id}"? It will no longer appear in your collection list.`,
        'Are you sure?',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'el-button--danger danger',
          center: true,
          autofocus: false,
        }
      )
        .then(() => {
          this.$store
            .dispatch('cloudTokens/deleteToken', token)
            .then(() => {
              ElNotification({
                title: 'Success',
                type: 'success',
              });
              // 由于此处的 list 并非直接取的 state 的值，所以无法动态更新，得手动处理
              this.list.splice(index, 1);
              this.$store.commit(
                'cloudTokens/UPDATE_COUNT',
                this.count - 1
              );
              this.dialog.visible = false;
            });
        })
        .catch(() => {});
    },
    checkToken(data) {
      const { traitIdList } = data;
      this.dialog.visible = true;
      this.dialog.dataSource = {
        ...data,
        rawTraitList: traitIdList.map(
          (item) => this.traitIdMapping[item]
        ),
      };
    },
    scrollLoad({ target }) {
      const { clientHeight, scrollHeight, scrollTop } = target;
      const { page, size } = this.pagination;
      if (scrollTop + clientHeight > (scrollHeight / 5) * 4) {
        // 这里的 total_count 是指在筛选主题后，根据主题返回的所有物料数量，而非全部物料数量 count
        if (!this.loading && page * size < this.total_count) {
          this.pagination.page++;
          this.getCloudTokens();
        }
      }
    },
    reset() {
      this.list = [];
      this.pagination.page = 1;
      this.loading = true;
    },
  },
};
</script>
<style lang="less" scoped>
@import url('@/assets/css/function.less');

.switch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: 14px;
  background-color: #fdfdfd;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  transition: height 0.3s;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 14px;
    background-color: #fff;
    cursor: pointer;
  }

  & > .count_button {
    position: absolute;
    left: 50%;
    bottom: 6px;
    z-index: -1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px 8px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
    line-height: 14px;
    cursor: pointer;
    transform: translate(-50%, 100%);

    & > .el-icon {
      margin-right: 6px;
    }

    // & > .animation {
    //   position: absolute;
    //   bottom: -5px;
    //   z-index: 1;
    //   width: 40px;
    //   height: 40px;
    //   background-color: @primary;
    //   border-radius: 50%;
    //   transition: all 0.3s;
    //   animation-name: fly_in;
    //   // animation-iteration-count: infinite;
    //   animation-duration: 0.6s;
    // }
  }

  & > .body {
    width: 100%;
    height: 100%;
    overflow: auto;
    content-visibility: auto;

    & > .form {
      position: sticky;
      top: 0;
      z-index: 100;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      padding: 68px 0 40px 0;
      background-color: #fff;
      .gutter(10px, 10px);
    }

    & > .list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 24px 80px 100px 80px;

      & > ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .gutter(20px, 20px);

        & > li {
          position: relative;
          background-color: #fff;
          border-radius: 14px;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
          overflow: hidden;
          transition: box-shadow 0.25s ease-in-out 0s;
          cursor: pointer;

          &:hover {
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);

            & > .token-wrapper {
              & > .token {
                transform: scale(1.2);
              }
            }

            & > .controls {
              height: 36px;
            }
          }

          & > .token-wrapper {
            position: relative;
            width: 200px;
            height: 200px;
            overflow: hidden;

            & > .token {
              position: relative;
              z-index: 1;
              transition: all 0.3s;
            }

            & > .generating-mask {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 2;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(255, 0, 255, 0.5);
              font-size: 22px;
              color: #fff;
            }
          }

          & > .desc {
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            color: @font-color;
          }

          & > .controls {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0;
            overflow: hidden;
            transition: all 0.3s;

            & > .el-icon {
              flex: 1;
              height: 100%;
              font-size: 24px;
              color: #fff;
              cursor: pointer;
              transition: all 0.3s;
            }

            & > .el-icon.delete {
              background-color: @danger;
            }
          }
        }
      }
    }
  }

  & > .fixed_buttons {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translate(-50%, 0);

    .el-button {
      height: 42px;
      border-radius: 20px;
      box-shadow: 0 0 1px 1px #ff00ff;
      font-size: 18px;
    }

    .btn--primary {
      border: 4px solid #fff;
      background-color: #ff00ff;
      color: #fff;
    }

    .btn--primary.is-disabled {
      background-color: #ffcdff;
      box-shadow: 0 0 1px 1px #ffcdff;
    }
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
}

.corner_btn {
  &::before {
    border-right: 80px solid #ea1c1c;
  }

  & > .el-icon {
    top: 38px;
    right: 5px;
    line-height: 32px;
    font-size: 32px;
  }
}
</style>

<style lang="less">
@keyframes fly_in {
  0% {
    transform: scale(1) translate(0, 50vh);
  }

  100% {
    transform: scale(0.2) translate(0, 0);
  }
}
</style>
