import axios from 'axios';
import { ElNotification } from 'element-plus';
import store from '@/store';
import { ACCESS_TOKEN_CACHEKEY } from '@/assets/constant';
import { throttle } from 'throttle-debounce';
import router from './router';
axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';
axios.defaults.timeout = 50000; // 请求的超时时间
axios.defaults.withCredentials = true; // 需要跨域携带认证}

const base = process.env.VUE_APP_API_PATH;
// const base = '/';

axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_CACHEKEY);
    config.headers.common['access-token'] = accessToken || '';
    if (window.crypto?.randomUUID)
      config.headers.common['x-reqid'] = window.crypto.randomUUID();
    return config;
  },
  (err) => Promise.reject(err)
);

const errorHandler = throttle(
  3000,
  (title = 'NETWORK ERROR') => {
    ElNotification({
      type: 'error',
      title,
    });
  },
  {
    noTrailing: true,
  }
);

const authErrorHandler = throttle(
  500,
  () => {
    ElNotification({
      type: 'error',
      title: 'Please login first',
    });
    store.commit('authority/resetAccountData');
  },
  {
    noTrailing: true,
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (entry) => {
    const { data, config, headers } = entry;
    // 在这里对返回的数据进行处理
    if (data.code === 401) {
      // 1. 返回首页
      // 2. 重新登录
      router.push({
        path: '/',
      });
      authErrorHandler();

      return Promise.resolve([data || '未登录！', null, headers]);
    }
    if (data.code !== 0) {
      if (!config._muted) {
        errorHandler(data.message);
      }
      return Promise.resolve([
        data || 'NETWORK ERROR',
        null,
        headers,
      ]);
    } else {
      return [null, data || {}, headers];
    }
  },
  (err) => {
    // errorHandler();
    return Promise.resolve([err || 'NETWORK ERROR', {}]);
  }
);

const request = (options) => {
  options.headers = Object.assign(options.headers || {}, {
    withCredentials: true,
  });
  options.method = options.method || 'get';
  if (options.method.toUpperCase() === 'GET') {
    options.params = options.data;
  }
  return axios(options);
};

export const getCollection = (collectionId) => {
  return request({
    url: base + `api/art/collection/${collectionId}`,
    method: 'get',
  });
};

export const updateCollection = (collectionId, data) => {
  return request({
    data,
    url: base + `api/art/collection/${collectionId}`,
    method: 'post',
  });
};

export const getThemesByCollection = (collectionId) => {
  return request({
    url: base + `api/art/collection/${collectionId}/themes`,
    method: 'get',
  });
};

export const createTheme = (collectionId, data) => {
  return request({
    data,
    url: base + `api/art/collection/${collectionId}/themes`,
    method: 'post',
  });
};

export const createCombo = (collectionId, data) => {
  return request({
    data,
    url: base + `api/art/collection/${collectionId}/combos`,
    method: 'post',
  });
};

export const deleteTheme = (collectionId, data) => {
  return request({
    data,
    url: base + `api/art/collection/${collectionId}/themes`,
    method: 'delete',
  });
};

export const createCollection = (data) => {
  return request({
    data,
    url: base + 'api/art/collection',
    method: 'post',
  });
};

export const uploadTraitsToCollection = (collectionId, data) => {
  return request({
    data,
    url: base + `api/art/collection/${collectionId}/trait`,
    method: 'post',
  });
};

export const deleteTrait = (collectionId, trait_id) => {
  return request({
    url:
      base +
      `api/art/collection/${collectionId}/trait?trait_id=${trait_id}`,
    method: 'delete',
  });
};

export const getCollectionById = (collectionId, theme) => {
  return request({
    url:
      base + `api/art/collection/${collectionId}?theme_name=${theme}`,
    method: 'get',
  });
};

export const getThemeTraitsByName = (collectionId, theme) => {
  return request({
    url:
      base + `api/art/collection/${collectionId}?theme_name=${theme}`,
    method: 'get',
  });
};

export const getNFTList = (data) => {
  return request({
    data,
    url: base + `api/art/nfts`,
    method: 'get',
  });
};

export const saveNFT = (data) => {
  return request({
    data,
    url: base + 'api/art/nfts/add',
    method: 'post',
    _muted: true,
  });
};

export const updateNFT = (nft_id, data) => {
  return request({
    data,
    url: base + `api/art/nfts/${nft_id}`,
    method: 'post',
  });
};

export const renameTrait = (collection_id, trait_id, data) => {
  return request({
    data,
    url:
      base + `api/art/collection/${collection_id}/trait/${trait_id}`,
    method: 'post',
  });
};

export const deleteNFT = (nft_id) => {
  return request({
    url: base + `api/art/nfts/${nft_id}`,
    method: 'delete',
  });
};

export const batchDeleteNFT = (nft_ids) => {
  return request({
    url: base + `api/art/nfts/bat-del?nft_ids=${nft_ids}`,
    method: 'delete',
  });
};

export const getNFTDetail = (data) => {
  return request({
    data,
    url: base + `api/art/nfts/traits`,
    method: 'get',
  });
};

export const uploadToBFS = (data) => {
  return request({
    data,
    url: base + 'api/upload/bfs/original',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const setClipData = (data) => {
  return request({
    data,
    url: base + 'api/piscina/script/extract',
    method: 'post',
  });
};

export const getClipData = (data) => {
  return request({
    data,
    url: base + 'api/piscina/script/extract',
    method: 'get',
    _muted: true,
  });
};

export const submitReview = (collectionId) => {
  return request({
    url: `${base}api/art/collection/${collectionId}/status`,
    method: 'post',
    data: {
      status: 1, // status 为 1，触发物料生成 tokenID
    },
  });
};

// 创建组合
export const updateCombos = (data) => {
  const { collection_id } = data;
  return request({
    url: `${base}api/art/collection/${collection_id}/combos`,
    method: 'post',
    data,
  });
};

// 修改组合名称
export const updateComboName = (data) => {
  const { collection_id } = data;
  return request({
    url: `${base}api/art/collection/${collection_id}/combos/update_name`,
    method: 'post',
    data,
  });
};

// 删除组合
export const deleteCombos = (data) => {
  const { collection_id } = data;
  return request({
    url: `${base}api/art/collection/${collection_id}/combos`,
    method: 'delete',
    data,
  });
};

// 获取金色属性
export const getGoldenData = (data) => {
  const { collection_id } = data;
  return request({
    url: `${base}api/art/collection/${collection_id}/golden_data`,
    method: 'get',
    data,
  });
};

export const getGanbeiNtfTraits = (nft_id) => {
  return request({
    url: `${base}api/art/nfts/traits?nft_id=${nft_id}`,
    method: 'get',
    _muted: true,
  });
};

export const setNtfDescription = ({ nft_id, description = '' }) => {
  return request({
    url: `${base}api/art/nfts/${nft_id}/set_description`,
    method: 'post',
    data: {
      description,
    },
  });
};

export const setToken = ({ nft_id, token_id }) => {
  return request({
    url: `${base}api/art/nfts/${nft_id}/set_token`,
    method: 'post',
    data: {
      token_id,
    },
  });
};

export const setNFT = (data) => {
  const { nft_id } = data;
  return request({
    url: `${base}api/art/nfts/${nft_id}`,
    method: 'post',
    data,
  });
};

export const getBossUploadId = (data) => {
  return request({
    url: `${base}api/boss/start-upload`,
    method: 'get',
    data,
  });
};

export const getBossUploadUrl = (data) => {
  return request({
    url: `${base}api/boss/get-upload-url`,
    method: 'get',
    data,
  });
};

export const getBossUploadResult = (data) => {
  return request({
    url: `${base}api/boss/complete-upload`,
    method: 'post',
    data,
  });
};

export const addTask = (data) => {
  return request({
    url: `${base}api/pipeline/task`,
    method: 'post',
    data,
  });
};

export const updateLayerName = (collection_id, data) => {
  return request({
    url: `${base}api/art/collection/${collection_id}/updateLayerName`,
    method: 'post',
    data,
  });
};

export const getJSONFile = (str) => {
  return request({
    url: `${base}api/upload/bfs/json`,
    method: 'post',
    data: {
      json: str,
    },
  });
};
// 成品上传接口
export const addMaterialToken = (data) => {
  return request({
    url: `${base}api/material/pack/add_token`,
    method: 'post',
    data,
  });
};
// 获取缩略图接口
export const getThumbnails = (file) => {
  return request({
    url: `${base}api/upload/bfs/thumbnail`,
    method: 'post',
    file,
  });
};

// 获取缩略图接口
export const getThumbnailsFromRemote = (url) => {
  return request({
    url: `${base}api/upload/bfs/thumbnail_from_remote`,
    method: 'post',
    data: {
      url,
    },
  });
};

// 获取系列接口
export const getCollections = (data) => {
  return request({
    url: `${base}api/art/collection`,
    method: 'get',
    data,
  });
};

// 获取用户信息
export const getProfile = (data) => {
  return request({
    url: `${base}api/auth/profile`,
    method: 'get',
    data,
  });
};

// 登录
export const login = (data) => {
  return request({
    url: `${base}api/auth/login`,
    method: 'post',
    data,
  });
};

// nonce
export const getNonce = (data) => {
  return request({
    url: `${base}api/auth/nonce`,
    method: 'post',
    data,
  });
};

export function createMaterial(data) {
  return request({
    url: `${base}api/material/pack/create`,
    method: 'post',
    data,
  });
}

// 删除藏品
export function deleteCollection(collectionId, data) {
  return request({
    url: `${base}api/art/collection/${collectionId}`,
    method: 'delete',
    data,
  });
}

// 设置元数据
export function setMetadataConfig(collectionId, data) {
  return request({
    url: `${base}api/art/collection/${collectionId}/set_metadata_config`,
    method: 'post',
    data,
  });
}

// 上传元数据至 ipfs
export function postMetadataConfigToIpfs(collectionId, data) {
  return request({
    url: `${base}api/art/collection/${collectionId}/ipfs`,
    method: 'post',
    data,
  });
}

// 上传盲盒元数据至 ipfs
export function postMetadataConfigToIpfsBlindBox(collectionId, data) {
  return request({
    url: `${base}api/art/collection/${collectionId}/ipfs/blind_box`,
    method: 'post',
    data,
  });
}

// 查询上传 ipfs 进度
export function getIpfsProgress(data) {
  return request({
    url: `${base}api/art/collection/ipfs/progress`,
    method: 'get',
    data,
    _muted: true,
  });
}

export function fetchTaskIds(collection_id) {
  return request({
    url: `${base}api/art/collection/${collection_id}/ipfs/task_ids`,
    method: 'get',
  });
}

// export function fetchNftsRenderProgress(collection_id) {
//   return request({
//     url: `${base}api/art/collection/${collection_id}/gen_count`,
//     method: "get",
//   });
// }

// 触发合成藏品
export const generateManually = (data) => {
  const { collectionId } = data;
  return request({
    url: `${base}api/art/collection/${collectionId}/gen_next`,
    method: 'get',
    data,
  });
};

export function killIpfsTask(taskId) {
  return request({
    url: `${base}api/art/collection/ipfs/clean?task_id=${taskId}`,
    method: 'get',
    _muted: true,
  });
}

// 更新藏品
// export function updateCollection (collectionId, data) {
//   return request({
//     url: `${base}api/art/collection/${collectionId}`,
//     method: 'post',
//     data,
//   })
// }

// 获取陀螺仪配置信息
export const getGyroscopeConfig = (data) => {
  const { collectionId } = data;
  return request({
    url: `${base}api/art/collection/${collectionId}/gyroscope`,
    method: 'get',
  });
};

// 更新陀螺仪配置信息
export const postGyroscopeConfig = (payload) => {
  const { collectionId, data } = payload;
  return request({
    url: `${base}api/art/collection/${collectionId}/gyroscope`,
    method: 'post',
    data,
  });
};

export const getMetadataByNFTID = (data) => {
  const { nft_id } = data;
  return request({
    url: `${base}api/crm/art/${nft_id}`,
    method: 'get',
    data,
  });
};

export const pinFileToIPFS = (data) => {
  return request({
    url: `${base}api/nft-storage/file/raw`,
    method: 'post',
    data,
  });
};

export const pinImageToIPFS = (data) => {
  return request({
    url: `${base}api/nft-storage/remote-image`,
    method: 'post',
    data,
  });
};

export const uploadHTMLToOSSByNftId = ({ nft_id }) => {
  return request({
    url: `${base}api/art/nfts/${nft_id}/gyroscope/html`,
    method: 'post',
  });
};

/**
 * 获取可组合的nft列表
 */
export const getNFTListV2 = (data) => {
  return request({
    data,
    url: base + `v2/api/art/nfts`,
    method: 'get',
  });
};
/**
 * 保存全部nft
 */
export const cloudNFT = (data) => {
  return request({
    data,
    url: base + 'v2/api/art/nfts/cloud',
    method: 'post',
    _muted: true,
  });
};
/**
 * 取消保存全部nft
 */
export const cancelCloudNFT = (data) => {
  return request({
    data,
    url: base + 'v2/api/art/nfts/cloud',
    method: 'delete',
    _muted: true,
  });
};
/**
 * 下载全部nft
 */
export const downloadNFT = (data) => {
  return request({
    data,
    url: base + 'v2/api/art/nfts/download',
    method: 'post',
    _muted: true,
  });
};
/**
 * 取消下载全部nft
 */
export const cancelDownloadNFT = (data) => {
  return request({
    data,
    url: base + 'v2/api/art/nfts/download',
    method: 'delete',
    _muted: true,
  });
};