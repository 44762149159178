<template>
  <div class="page--full">
    <div class="main">
      <div class="description">
        <strong>A Crypto Art Generator</strong>
        <p>Generative Art. Community Drops.</p>
        <p>Artist Collabs. Made easy.</p>
      </div>
      <el-button
        v-if="account.address"
        class="primary-btn"
        @click="$router.push('/collections')"
        >COLLECTIONS
      </el-button>
      <Wallet v-else />
    </div>
    <div class="footer">
      <div class="links">
        <el-popover :width="200" trigger="hover">
          <template #reference>
            <i class="iconfont icon-wechat"></i>
          </template>
          <img
            src="@/assets/images/0xone-qrcode.jpg"
            alt=""
            class="image-0xone-qrcode"
          />
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Wallet from "@/components/Wallet";
export default {
  components: {
    Wallet,
  },
  computed: {
    ...mapState("authority", ["account"]),
  },
};
</script>

<style lang="less" scoped>
.page--full {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin-top: -80px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    // animation-name: zoom_in;
    // animation-timing-function: linear;
    // animation-iteration-count: infinite;
    // animation-duration: 1000s;
    // transition: all 0.3s;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(140, 26, 265, 0.5);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

  .main {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1000px;
    height: 100vh;
    margin: 0 auto;
    text-align: center;

    .description {
      margin-bottom: 32px;

      & > strong {
        display: block;
        margin-bottom: 16px;
        font-family: "Robaga Rounded Black";
        font-size: 72px;
        color: @white;
      }

      & > p {
        font-family: "Robaga Rounded Regular";
        font-size: 28px;
        color: @white;
      }
    }

    :deep(.wallet-entrance) {
      background-color: @primary;
      border-color: @white;
      color: @white;
    }

    & > .primary-btn {
      height: 48px;
      min-width: 64px;
      padding: 6px 24px;
      border: 4px solid @white;
      border-radius: 28px;
      background-color: @primary;
      font-family: "Robaga Rounded Black";
      font-size: 18px;
      line-height: 18px;
      color: @white;
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding: 40px 24px;

    & > .links {
      & > .iconfont {
        font-size: 24px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="less">
.image-0xone-qrcode {
  display: block;
  width: 100%;
}
@keyframes zoom_in {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(10);
  }

  100% {
    transform: scale(1);
  }
}
</style>
