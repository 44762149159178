import {
  getThemesByCollection,
  createTheme,
  deleteTheme,
} from '@/api';

export default {
  namespaced: true,
  state: {
    list: [],
    current: {},
  },
  mutations: {
    UPDATE_LIST(state, list) {
      state.list = list;
    },
    UPDATE_CURRENT(state, current) {
      state.current = current || {};
    },
  },
  actions: {
    getList({ rootState, commit }) {
      const { collection } = rootState;
      const { id } = collection;
      return new Promise((resolve, reject) => {
        getThemesByCollection(id).then(([err, res]) => {
          if (err) {
            reject(err);
          } else {
            commit('UPDATE_LIST', res.data);
            resolve(res);
          }
        });
      });
    },
    addNewTheme({ rootState, state, commit }, payload) {
      const { collection } = rootState;
      const { id } = collection;
      const { list } = state;
      const { theme_name, traits } = payload;
      return new Promise((resolve, reject) => {
        createTheme(id, { theme_name, traits }).then(([err, res]) => {
          if (err) {
            reject(err);
          } else {
            const { theme_id } = res.data[0];
            const index = list.findIndex(
              (item) => item.theme_id === theme_id
            );
            const newTheme = { theme_id, theme_name };
            if (index === -1) {
              list.push(newTheme);
            } else {
              list.splice(index, 1, newTheme);
            }
            commit('UPDATE_LIST', list);
            resolve(res.data);
          }
        });
      });
    },
    deleteTheme({ rootState, state, commit }, payload) {
      return new Promise((resolve, reject) => {
        const { collection } = rootState;
        const { id } = collection;
        const { list } = state;
        const { theme_name, index } = payload;
        deleteTheme(id, { theme_name }).then(([err]) => {
          if (err) {
            reject(err);
          } else {
            list.splice(index, 1);
            commit('UPDATE_LIST', list);
            resolve();
          }
        });
      });
    },
  },
};
