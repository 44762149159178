// import { getWorker } from '@/utils';
import { getNFTListV2 } from '@/api.js';

// const worker = getWorker();
export default {
  namespaced: true,
  state: {
    list: [],
    count: 0,
  },
  mutations: {
    UPDATE_LIST(state, list) {
      state.list = list;
    },
    UPDATE_COUNT(state, count) {
      state.count = count;
    },
  },
  actions: {
    presetTokens({ rootState, rootGetters, state, commit }, payload = {}) {
      return new Promise((resolve) => {
        console.log('rootState', rootState);
        console.log('rootGetters', rootGetters);
        const { collection, traits, traitRules } = rootState;
        const { combination } = traits;
        const { 'traits/layers': layers } = rootGetters;
        const newLayers = [...layers].map(([, traits]) => {
          const visibleTraits = traits.filter((item) => !item.hidden);
          const selectedTraits = visibleTraits.filter((item) =>
            combination.has(item.trait_id)
          );
          return selectedTraits.length
            ? selectedTraits
            : visibleTraits;
        });
        const { count } = state;
        const { page, pageSize } = payload;
        console.log('page, pageSize, count', page, pageSize, count);
        console.log(layers);
        const rules = traitRules.list.filter((item) => item.active);
        console.log({
          layers: newLayers,
          page,
          pageSize,
          // count,
          rules: rules,
          traits: traits.list,
        });
        const trait_ids = [];
        for (const layer of newLayers) {
          trait_ids.push(...layer.map(({ trait_id }) => trait_id))
        }
        if (trait_ids.length) {
          getNFTListV2({
            collection_id: collection.id,
            page_num: page,
            page_size: pageSize,
            traits: trait_ids.join(','),
          }).then(([err, data]) => {
            console.log('getNFTListV2', data);

            if (err) {
              resolve([]);
            } else {
              resolve(data?.data?.list ?? []);
              commit(
                'UPDATE_COUNT',
                data?.data?.total ?? 0
              );
            }
          });
        } else {
          resolve([]);
        }
        // worker
        //   ?.work('presetTokens', {
        //     layers: JSON.parse(JSON.stringify(newLayers)),
        //     page,
        //     pageSize,
        //     count,
        //     rules: JSON.parse(JSON.stringify(rules)),
        //     traits: JSON.parse(JSON.stringify(traits.list)),
        //   })
        //   ?.then((data) => {
        //     console.log('presetTokens data', data);
        //     resolve(data);
        //   })
        //   ?.catch(() => {
        //     console.log('worker error');
        //     reject();
        //   });
      });
    },
    generateTokens({ commit, dispatch }, payload = {}) {
      // const { list } = state;
      return new Promise((resolve) => {
        dispatch('presetTokens', payload).then((res) => {
          // if (page) {
          //   list.push(...data);
          //   // commit('UPDATE_LIST', list);
          // } else {
          commit('UPDATE_LIST', res);
          resolve(res);
          // }
        });
      });
    },
    calculateCount({ rootState, rootGetters, commit }) {
      return new Promise(() => {
        const { traits, traitRules } = rootState;
        const { combination } = traits;
        const { 'traits/layers': layers } = rootGetters;
        const rules = traitRules.list.filter((item) => item.active);
        const newLayers = [...layers.values()].map((item) => {
          const visibleArr = item.filter(
            (childItem) => !childItem.hidden
          );
          const chosenArr = visibleArr.filter((childItem) =>
            combination.has(childItem.trait_id)
          );
          return chosenArr.length ? chosenArr : visibleArr;
        });
        const count = newLayers
          .map((item) =>
            item.filter((childItem) => !usedByRules(rules, childItem))
          )
          .map((item) => item.length)
          .reduce((prod, cur) => {
            console.log('count:', prod , cur);
            return prod * cur
          }, 1);
        const countWithRules = otherCount(
          rules,
          newLayers,
          traits.list
        );
        console.log('calculateCount', count , countWithRules);
        commit(
          'UPDATE_COUNT',
          layers.size ? count + countWithRules : 0
        );
      });
    },
  },
};

const usedByRules = (rules, { trait_id }) => {
  const ruleKeys = rules.map((item) => item.key);
  const ruleValues = [
    ...new Set(
      rules
        .filter((item) => item.operator === 'pairs')
        .map((item) => item.values)
        .flat()
    ),
  ];
  return ruleKeys.includes(trait_id) || ruleValues.includes(trait_id);
};

const otherCount = (rules, layers, traits) => {
  const activeTraitIds = layers
    .flat()
    .map(({ trait_id }) => trait_id);
  const avaliableRules = rules.filter(({ key }) =>
    activeTraitIds.includes(key)
  );
  return avaliableRules
    .map(({ key, operator, values }, index) => {
      const keyTrait = traits.find((item) => item.trait_id === key);
      const valuesTraits = values.map((item) =>
        traits.find((findItem) => findItem.trait_id === item)
      );
      return layers
        .filter((item) => item?.[0]?.type !== keyTrait.type)
        .map((item) => {
          // 当前图层下的所有素材
          const filteredTraits = valuesTraits.filter(
            (childItem) => childItem.type === item?.[0]?.type
          );
          const filteredTraitIds = filteredTraits.map(
            (childItem) => childItem.trait_id
          );
          // 被 pairs 的素材，不能再用
          const otherPairsTraitIds = avaliableRules
            .filter((childItem, childIndex) => childIndex !== index)
            .map((childItem) => childItem.values)
            .flat();
          switch (operator) {
            case 'doesnt':
              if (filteredTraits.length) {
                return item.filter(
                  (childItem) =>
                    ![
                      ...filteredTraitIds,
                      ...otherPairsTraitIds,
                    ].includes(childItem.trait_id)
                );
              } else {
                return item;
              }
            case 'only':
            case 'pairs':
              if (filteredTraits.length) {
                return item.filter((childItem) =>
                  filteredTraitIds.includes(childItem.trait_id)
                );
              } else {
                return item;
              }
          }
        })
        .reduce((prod, cur) => prod * cur.length, 1);
    })
    .reduce((sum, cur) => sum + cur, 0);
};
