import { ElMessage } from 'element-plus';
import { create, all } from 'mathjs';
import localforage from 'localforage';
import svgo from 'svgo/dist/svgo.browser.js';

export const MaterialTypes = {
  TranslateGyroscope: 7,
  OpacityGyroscope: 8,
};

const hash = require('string-hash');
const config = {
  number: 'BigNumber',
  precision: 20,
};
const mathCalc = create(all, config);

export const math = {
  //除
  divide: function (arg1, arg2) {
    return mathCalc.divide(arg1, arg2);
  },
  //乘
  multiply: function (arg1, arg2) {
    return mathCalc.multiply(arg1, arg2);
  },
  //加
  add: function (arg1, arg2) {
    return mathCalc.add(arg1, arg2);
  },
  //减
  subtract: function (arg1, arg2) {
    return mathCalc.add(arg1, -arg2);
  },
};

export const stringConvertor = (url = '') => {
  return url.replace(
    /^https?:/,
    location.protocol === 'https:' ? 'https:' : 'http:'
  );
};

export const fetchContentFromUrl = (url) => {
  if (!url) {
    return Promise.resolve('');
  }

  return new Promise((res) => {
    fetch(url)
      .then((response) => {
        res(response.text());
      })
      .catch(() => {
        res('');
      });
  });
};

export const isSVGEmpty = (path = '') =>
  !path.match(/^<svg.*?>(.+)<\/svg>$/);

export const svgProcessor = (inputPath) => {
  const { data } = svgo.optimize(inputPath, {
    plugins: [
      'removeDimensions',
      {
        name: 'preset-default',
        params: {
          overrides: {
            inlineStyles: {
              onlyMatchedOnce: false,
            },
            collapseGroups: false,
          },
        },
      },
      // 'cleanupAttrs'
      {
        name: 'cleanupIDs',
        params: {
          prefix: {
            toString() {
              return `svg-${hash(inputPath)}`; // avoid duplicated "pattern" ids
            },
          },
        },
      },
    ],
  });
  return data;
};

export const colorMatchGanbei = (groupedPath, metaData) => {
  const bAvatarSkinColorRegex = /fill=("#FFBEB4"|"#FFBDB3")/g; // customized for "company avatar" project.
  const colorMapping = [
    { layerName: '头', itemName: '肉色', color: '#FFBEB4' },
    { layerName: '头', itemName: '醉酒色', color: '#DB7878' },
    { layerName: '头', itemName: '青色', color: '#BECDE1' },
    { layerName: '头', itemName: '白色', color: '#FFFFFF' },
  ];
  const findCommonItem = (array1, array2) => {
    return array1.filter((o) =>
      array2.some(
        ({ trait_type, value }) =>
          o.layerName === trait_type && o.itemName === value
      )
    );
  };

  if (groupedPath.match(bAvatarSkinColorRegex)) {
    let overrideColorLayer = findCommonItem(
      colorMapping,
      metaData
    )[0];
    if (overrideColorLayer)
      groupedPath = groupedPath.replace(
        bAvatarSkinColorRegex,
        `fill="${overrideColorLayer.color}"`
      );
  }

  return groupedPath;
};

export const cacheHelper = (() => {
  const storeName = 'cacheHelper';

  localforage.config({
    storeName,
  });

  async function save(key, value) {
    try {
      await localforage.setItem(key, value);
      return Promise.resolve();
    } catch (e) {
      console.log('cache helper error', e);
    }
  }

  async function get(key) {
    try {
      const data = await localforage.getItem(key);
      return Promise.resolve(data);
    } catch (e) {
      console.log('cache helper error', e);
    }
  }

  async function clean() {
    try {
      await localforage.clear();
      return Promise.resolve();
    } catch (e) {
      console.log('cache helper error', e);
    }
  }

  return {
    set: save,
    save,
    get,
    clear: clean,
    clean,
  };
})();

export function isBelongTo(arr, arr2, strict = false) {
  let subArray = arr.length >= arr2.length ? arr2 : arr;
  let wholeArray = subArray === arr ? arr2 : arr;
  let result = true;

  if (strict) {
    subArray = arr;
    wholeArray = arr2;
  }

  for (let i = 0; i < subArray.length; i++) {
    let item = subArray[i];

    if (!wholeArray.includes(item)) {
      result = false;
      break;
    }
  }

  return result;
}

export function iterateNumber(from, to, totalTime = 1000) {
  const stepCount = 24;
  const unitStep = (to - from) / stepCount;
  const start = Date.now();

  return () => {
    let result = 0;
    const duration = Date.now() - start;

    if (duration >= totalTime) {
      result = to;
    } else {
      result = Math.ceil(
        from + unitStep * ((duration / totalTime) * stepCount)
      );
    }
    return result;
  };
}

export const getWorker = (() => {
  const subscriberTable = {};
  let worker = null;
  const loadingFlags = {};
  const registry = {};

  function register(name) {
    registry[name] = function (params) {
      if (loadingFlags[name]) {
        return Promise.reject();
      }
      loadingFlags[name] = true;
      return new Promise((resolve) => {
        function receive(data) {
          loadingFlags[name] = false;
          resolve(data);
        }

        if (subscriberTable[name]) {
          subscriberTable[name].push(receive);
        } else {
          subscriberTable[name] = [receive];
        }

        worker.postMessage({
          name,
          ...params,
        });
      });
    };
  }

  function work(name, ...params) {
    if (!worker) {
      setTimeout(() => {
        work(name, ...params);
      }, 300);
      return;
    }
    if (!registry[name]) {
      console.log(`unknow job ${name}`);
      return Promise.reject();
    }
    return registry[name](...params);
  }

  function close() {
    worker.terminate();
  }

  return function () {
    if (!worker) {
      let scriptSrc = document.currentScript.getAttribute('src');
      scriptSrc = scriptSrc.split('/');
      scriptSrc.pop();
      const path = `${
        process.env.NODE_ENV === 'development'
          ? '.'
          : scriptSrc.slice(0, scriptSrc.length - 1).join('/')
      }/worker/index.js`;
      fetch(path)
        .then((res) => res.text())
        .then((codeString) => {
          const localWorkerUrl = window.URL.createObjectURL(
            new Blob([codeString], {
              type: 'application/javascript',
            })
          );

          worker = new Worker(localWorkerUrl);

          register('presetTokens');

          worker.onmessage = function (e) {
            const { data, error } = e;

            if (error) {
              console.log('worker error => ', error);
              return;
            }

            const { name, result } = data;

            if (subscriberTable[name]) {
              while (subscriberTable[name].length) {
                const fn = subscriberTable[name].shift();
                fn && fn(result);
              }
            }
          };
        });
    }

    return {
      work,
      close,
    };
  };
})();

export function getLayerAndTraitData(
  themesArray = [],
  layerIndex = {}
) {
  const layerMapping = {};
  const traitsData = {};

  themesArray.forEach((themes) => {
    themes.data.forEach((item) => {
      const { type, value, trait_count } = item || {};
      if (layerMapping[type]) {
        if (!layerMapping[type].includes(value)) {
          layerMapping[type].push(value);
        }
      } else {
        layerMapping[type] = [value];
      }

      const key = `${type}:${value}`;
      if (traitsData[key]) {
        traitsData[key].count += trait_count;
      } else {
        traitsData[key] = {
          count: trait_count,
        };
      }
    });
  });

  const sortedMapping = {};
  const keys = [];
  Object.keys(layerMapping).map((key) => {
    const index = layerIndex[key];
    keys[index] = key;
  });
  keys.forEach((key) => {
    const items = layerMapping[key].sort();
    sortedMapping[key] = items;
  });

  return { layerMapping: sortedMapping, traitsData };
}

export function getLayerMappingAndTraitsData(data = []) {
  // [{name, layerIndex, elements}, ...]
  const layerMapping = {};
  const traitsData = {};

  data.forEach((layer) => {
    const { elements } = layer;
    elements.forEach((item) => {
      const { type, value, trait_count } = item || {};
      if (layerMapping[type]) {
        if (!layerMapping[type].includes(value)) {
          layerMapping[type].push(value);
        }
      } else {
        layerMapping[type] = [value];
      }

      const key = `${type}:${value}`;
      if (traitsData[key]) {
        traitsData[key].count += trait_count;
      } else {
        traitsData[key] = {
          count: trait_count,
        };
      }
    });
  });
  return { layerMapping, traitsData };
}

export function getUrlParams(key) {
  return new URLSearchParams(
    (window.location.hash.split('?') || [])[1]
  ).get(key);
}

export function checkFileType(item, type) {
  if (Array.isArray(type)) {
    return type.some(function (perType) {
      return item.toLowerCase().endsWith(`.${perType}`);
    });
  } else {
    return item.toLowerCase().endsWith(`.${type}`);
  }
}

export function stackPromise(promiseArray, stackLength = 6) {
  return new Promise((resolve) => {
    const total = promiseArray.length;
    let index = 0;
    let count = 0;
    let idleSpace = stackLength;
    const result = [];

    const request = () => {
      while (index < total && idleSpace > 0) {
        idleSpace -= 1;

        let currentPromise = promiseArray[index];
        currentPromise().then((res) => {
          result.push(res);

          idleSpace += 1;
          count += 1;

          if (count === total) {
            resolve(result);
          } else {
            request();
          }
        });

        index += 1;
      }
    };

    request();
  });
}

export function wait(time = 100) {
  return new Promise((res) => {
    setTimeout(() => {
      res();
    }, time);
  });
}

export function getCookie(name) {
  let result = document.cookie.match(`[;\\s+]?${name}=([^;]*)`);
  result = result ? result.pop() : '';
  return result;
}

export async function getFile(fileEntry) {
  try {
    return await new Promise((resolve, reject) =>
      fileEntry.file(resolve, reject)
    );
  } catch (err) {
    console.log(err);
  }
}

export const isLink = (url) => {
  return /^(https:\/\/|http:\/\/)/.test(url);
};

export function getTraitTypeFromMaterialType(materialType) {
  let traitType = '';
  switch (materialType) {
    case 0:
    case 1:
    case 2:
    case 5: // blender2Image
    case 6:
    case 7:
    case 8:
      traitType = 'image';
      break;
    case 3:
      traitType = 'video';
      break;
    case 4:
      traitType = 'music';
      break;
  }
  return traitType;
}

export const svgBlob = (encode) => {
  return URL.createObjectURL(
    new Blob([encode], { type: 'image/svg+xml' })
  );
};

export const blobUrl = (encode, traitType) => {
  return traitType === 'svg'
    ? svgBlob(encode)
    : traitType === 'music'
    ? encode
    : // bfs
      // : stringConvertor(`${encode}@400w_400h_1c.webp`);
      // 阿里云
      aliOSSImage(encode, 'w400');
};
/**
 *
 * @param {String} url
 * @param {String} rule w100, w400, w800, info
 * @returns
 */
export const aliOSSImage = (url = '', rule = 'info') => {
  if (url) {
    const trimmedUrl = url.split('?')[0];
    return stringConvertor(
      `${trimmedUrl}?x-oss-process=style/${rule}`
    );
  } else {
    return '';
  }
};

export function downloadFile(fileUrl, fileName) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', fileUrl, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    const url = window.URL.createObjectURL(xhr.response);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || fileUrl.split('/').pop();
    a.click();
  };
  xhr.send();
}

export const getImageSize = (src) => {
  return new Promise((resolve) => {
    const imageDom = document.createElement('img');
    imageDom.src = src;
    imageDom.style.zIndex = -1;
    imageDom.style.position = 'absolute';
    imageDom.style.display = 'none';
    imageDom.style.opacity = 0;
    document.body.appendChild(imageDom);
    imageDom.onload = () => {
      const { naturalWidth, naturalHeight } = imageDom;
      resolve({
        width: naturalWidth,
        height: naturalHeight,
      });
      document.body.removeChild(imageDom);
    };
  });
};

export const getFileByEntry = (entry) => {
  return new Promise((resolve) => {
    entry.file((file) => {
      resolve(file);
    });
  });
};

export const clipboard = (text) => {
  const textareaEl = document.createElement('textarea');
  textareaEl.style.opacity = 0;
  textareaEl.setAttribute('readonly', 'readonly'); // 防止手机上弹出软键盘
  textareaEl.value = text;
  document.body.appendChild(textareaEl);
  textareaEl.select();
  document.execCommand('copy');
  document.body.removeChild(textareaEl);
  ElMessage({
    type: 'success',
    message: '复制成功',
  });
};

function testStep3HasMultiNft() {
  const nfts = window.listPanel.artList.map(
    (item) => item.traitIdList
  );
  const hash = {};
  let result = false;
  nfts.forEach((item) => {
    const key = item.sort((a, b) => {
      return a - b;
    });
    if (hash[key]) {
      hash[key] += 1;
      result = key;
    } else {
      hash[key] = 1;
    }
  });
  return !!result;
}

window.testStep3HasMultiNft = testStep3HasMultiNft;

/**
 * 转换图片
 * @param {String} url
 * @param {String} type base64 | blob
 * @returns
 */
export const convertImage = (url, type) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = url + '?t=' + new Date().getTime();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      switch (type) {
        case 'base64':
          resolve(canvas.toDataURL());
          break;
        case 'blob':
          canvas.toBlob((blob) => {
            resolve(blob);
          });
          break;
      }
    };
    img.onerror = () => {
      reject('图片加载失败，图片地址为空或者其他问题');
    };
  });
};
