import { getGyroscopeConfig, postGyroscopeConfig } from '@/api';

export default {
  namespaced: true,
  state: {
    config: [],
  },
  mutations: {
    updateConfig (state, payload) {
      state.config = [...payload];
    },
  },
  actions: {
    fetchAsyncGetConfig (context, payload) {
      return new Promise((resolve, reject) => {
        const { collectionId } = payload;
        getGyroscopeConfig({ collectionId })
          .then((res) => {
            const data = res?.[1]?.data || [];
            context.commit('updateConfig', data);
            resolve(res?.[1] || {});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchAsyncPostConfig (context, payload) {
      return new Promise((resolve, reject) => {
        const { collectionId, data } = payload;
        postGyroscopeConfig({ collectionId, data })
          .then((res) => {
            context.commit('updateConfig', data);
            resolve(res?.[1] || {});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
