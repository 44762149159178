<template>
  <div
    :class="{
      'top_bar--pain': $route.path === '/',
    }"
    class="top_bar"
  >
    <div class="top_bar-left">
      <router-link to="/collections" class="title">
        <el-button>0xone</el-button>
      </router-link>
    </div>
    <div class="top_bar-right">
      <el-space>
        <Wallet />
      </el-space>
    </div>
  </div>
</template>
<script>
import Wallet from '@/components/Wallet';
export default {
  components: {
    Wallet,
  },
};
</script>
<style scoped lang="less">
.top_bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 24px;
  background-color: @primary;
  border-bottom: 4px solid @black;

  .top_bar-left {
    .title {
      text-decoration: none;

      :deep(.el-button) {
        height: 48px;
        min-width: 64px;
        padding: 6px 24px;
        border: 4px solid #000;
        border-radius: 28px;
        background-color: #fff;
        font-family: 'Robaga Rounded Black';
        font-size: 22px;
        line-height: 22px;
        color: @black;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          color: @primary;
        }
      }
    }
  }
}
.top_bar--pain {
  background-color: transparent;
  border-bottom: 4px solid transparent;
}
</style>
