export const SVG_CACHEKEY = "svgTraitCache";
export const MUSIC_CACHEKEY = "musicTraitCache";
export const JSON_CACHEKEY = "jsonFileCache";
export const PNG_EMPTY_INDICATE = "无";
export const EMPTY_ITEM_NAME = "*空层*";
export const HEADER_HEIGHT = 70;
export const LINE_MODE_ART_ITEM_HEIGHT = 321;
export const GRID_MODE_ART_ITEM_HEIGHT = 205;
export const GRID_MODE_ONE_LINE_COUNT = 5;
export const MATERIAL_TYPE = {
  Avatar: 0, //生成物料头像
  // 'Image': 1,
  // 'Finished Face': 2, //成品物料头像
  // 'Video': 3,
  // 'Music Album': 4,
  // 'Blender 2 Avatar': 5, // blender 生成头像
  // 'Animation': 6,
  "Translate Gyroscope": 7, // 偏移陀螺仪
  // "Opacity Gyroscope": 8, // 闪卡陀螺仪
};
export const SUPPERT_FILE_SUFFIX = {
  0: ["png", "jpg", "jpeg", "webp"],
  1: ["png", "jpg", "jpeg", "webp"],
  2: ["png", "jpg", "jpeg", "webp"],
  4: ["mp3", "wav", "m4a", "xlsx", "png", "jpg", "jpeg", "webp"],
  5: ["blend", "js", "py", "png", "jpg", "jpeg", "webp"],
  6: ["png", "jpg", "jpeg", "webp"],
  7: ["png", "jpg", "jpeg", "webp"],
  8: ["png", "jpg", "jpeg", "webp"],
};
// 最大图层数量
export const MAX_LAYER_COUNT = 40;

export const ACCESS_TOKEN_CACHEKEY = "AccessTokenCache";
export const CONNECT_WAY_CACHEKEY = "ConnectWayCache";

export const statusMapping = {
  0: "筛选中",
  1: "审核中",
  2: "审核完成",
  3: "已使用",
};

export const STEPS = [
  {
    label: "上传素材",
    name: "step1",
  },
  {
    label: "素材管理",
    name: "step2",
  },
  {
    label: "物料生成",
    name: "step3",
  },
  {
    label: "物料确认",
    name: "step4",
  },
  // {
  //   label: '智能合约',
  //   name: 'step5',
  // },
];

export const nft_status = {
  1: "生成中",
  2: "生成成功",
  6: "过期的-表示图层素材更换了",
};
