import { getCollections } from '@/api';
export default {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
    UPDATE_LIST (state, list) {
      state.list = list;
    }
  },
  actions: {
    getList ({ commit }, payload) {
      const { page } = payload;
      return new Promise((resolve, reject) => {
        getCollections({ group_id: 0, page_num: page, page_size: 100 }).then(([err, res]) => {
          if (err) {
            reject(err);
          } else {
            commit('UPDATE_LIST', res.data.list);
            resolve(res);
          }
        });
      });
    }
  }
};