<template>
  <div class="body">
    <div class="body__left">
      <Previewer v-if="gyroscope.show" :type="info.material_type" :dataSource="gyroscopeData" />
      <Token v-else :rawUrl="rawUrl" ossStyle="w800" :traits="formattedTraits" />
    </div>
    <div class="body__right">
      <div class="title">
        <span>{{ info.collection_name }}</span>
        <strong>{{ current?.theme_name }}</strong>
        <div class="sub-title">Properties</div>
      </div>
      <div class="properties">
        <ul>
          <li v-for="(item, index) in traits" :key="index" class="item">
            <el-dropdown :max-height="300" :disabled="readonly" popper-class="components_dialog_token_detail_dropdown"
              placement="bottom-start" trigger="click">
              <span class="el-dropdown-link">
                <div class="left">
                  <Trait :width="45" :height="45" :name="item.value" :img-src="aliOSSImage(item.layer_url, 'w400')" />
                </div>
                <div class="right">
                  <span :title="item.type">{{ item.type }}</span>
                  <strong :title="item.value">{{ item.value }}</strong>
                </div>
                <el-icon v-if="!readonly">
                  <DCaret />
                </el-icon>
              </span>
              <template v-if="!readonly" #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="childItem of traitList(item)" :key="childItem.key"
                    @click="replaceTrait(childItem, item)">
                    <Trait :width="20" :height="20" :name="childItem.value"
                      :img-src="aliOSSImage(childItem.layer_url, 'w400')" />
                    <strong class="name" :title="childItem.value">{{
    childItem.value
}}</strong>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </li>
        </ul>
      </div>
      <div class="body_right__bottom">
        <!-- 当物料类型为偏移/闪卡陀螺仪时显示 -->
        <el-checkbox v-if="[7, 8].includes(info.material_type)" v-model="gyroscope.show" border>Gyroscope</el-checkbox>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { DCaret } from "@element-plus/icons-vue";
import Token from "@/components/Token";
import Trait from "@/components/Trait";
import Previewer from "@/components/Gyroscope/components/Previewer";
import { aliOSSImage } from "@/utils";
export default {
  components: {
    DCaret,
    Token,
    Trait,
    Previewer,
  },
  props: {
    rawUrl: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    traits: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      gyroscope: {
        show: false,
      },
    };
  },
  computed: {
    ...mapState("collection", ["info"]),
    ...mapState("themes", ["current"]),
    ...mapGetters("traits", ["layers"]),
    ...mapState("gyroscope", {
      gyroscopeConfig: ({ config }) => config,
    }),
    traitList() {
      return ({ type }) => {
        return this.layers.get(type);
      };
    },
    formattedTraits() {
      return this.traits.map((item) => ({
        ...item,
        layer_url: aliOSSImage(item.layer_url, "w800"),
      }));
    },
    gyroscopeData() {
      const traits = this.traits;
      return (this.gyroscopeConfig.length ? this.gyroscopeConfig : traits).map(
        (item) => {
          // eslint-disable-next-line
          const file_url = traits.find(
            (childItem) => childItem.layer_index == item.layer_index
          )?.layer_url;
          return {
            ...item,
            file_url,
          };
        }
      );
    },
  },
  methods: {
    aliOSSImage,
    replaceTrait(newTrait, oldTrait) {
      this.$emit("replaceTrait", {
        newTrait,
        oldTrait,
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("~@/assets/css/function.less");

.body {
  display: flex;
  height: 500px;

  &>.body__left {
    width: 500px;
    height: 100%;

    &>.previewer {
      width: 100%;
      height: 100%;
    }
  }

  &>.body__right {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 0 36px;
    overflow: auto;

    &>.title {
      position: sticky;
      top: 0;
      z-index: 1;
      padding-top: 36px;
      padding-bottom: 8px;
      background-color: #fff;

      &>span {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        color: @font-color;
      }

      &>strong {
        display: block;
        margin-bottom: 24px;
        line-height: 24px;
        font-size: 24px;
        font-weight: 700;
        color: @font-color;
      }

      &>.sub-title {
        font-weight: normal;
        color: @font-color--light;
      }
    }

    &>.properties {
      flex: 1;
      margin-bottom: 24px;

      &>ul {
        display: flex;
        flex-wrap: wrap;
        .gutter(14px, 14px);

        &>li {
          width: calc((100% - 14px * 3) / 3);
          border: 1px solid #dde3e7;
          border-radius: 4px;
          box-sizing: border-box;
          transition: all 0.3s;
          cursor: pointer;

          &:hover {
            border-color: rgb(255, 0, 255);

            &>.el-dropdown {
              &>.el-dropdown-link {
                &>.el-icon {
                  color: rgb(255, 0, 255);
                }
              }
            }
          }

          &>.el-dropdown {
            width: 100%;
            cursor: pointer !important;

            &>.el-dropdown-link {
              position: relative;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding: 8px;

              &>.left {
                flex-shrink: 0;
              }

              &>.right {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 0 8px;
                overflow: hidden;
                text-align: center;

                &>span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 12px;
                  line-height: 14px;
                  color: @font-color--light;
                }

                &>strong {
                  margin: 4px 0;
                  overflow: hidden;
                  font-size: 14px;
                  line-height: 16px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: @font-color;
                }
              }

              &>.el-icon {
                position: absolute;
                top: 50%;
                right: 4px;
                color: #dde3e7;
                transform: translateY(-50%);
                transition: color 0.3s;
              }
            }
          }
        }
      }
    }

    &>.body_right__bottom {
      position: sticky;
      bottom: 0;
      z-index: 1;
      padding-top: 8px;
      padding-bottom: 36px;
      background-color: #fff;
    }
  }
}
</style>

<style lang="less">
@import url("~@/assets/css/function.less");

.components_dialog_token_detail_dropdown {
  width: 134px;

  .el-dropdown-menu__item {
    display: flex;
    justify-content: space-between;
    font-size: 12px;

    &>.name {
      width: calc(100% / 2);
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &>.trait-wrapper {
      &>.trait {
        .transparent_background(5);
      }
    }
  }
}
</style>
