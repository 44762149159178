import { createStore } from 'vuex';
import authority from './authority';
import collectionList from './collectionList';
import collection from './collection';
import themes from './themes';
import traits from './traits';
import cloudTokens from './cloudTokens';
import presetTokens from './presetTokens';
import gyroscope from './gyroscope';
import traitRules from './traitRules';

export default createStore({
  modules: {
    authority,
    collectionList,
    collection,
    themes,
    traits,
    cloudTokens,
    presetTokens,
    gyroscope,
    traitRules,
  },
});
