<template>
  <el-table
    :data="tableData"
    :default-sort="{ prop: 'layer_index', order: 'ascending' }"
    :expand-row-keys="expandRowKeys"
    :row-class-name="rowClassName"
    height="50vh"
    row-key="layer_name"
    border
  >
    <el-table-column type="expand" width="1" min-width="1">
      <template #default="props">
        <el-table
          :data="props.row.traits"
          size="small"
          class="table--inner"
        >
          <el-table-column type="index" width="48" align="center" />
          <el-table-column prop="thumbnail" label="Image" width="67">
            <template #default="scope">
              <Trait
                :width="50"
                :height="50"
                :img-src="scope.row.thumbnail"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="file_name"
            label="File Name"
          ></el-table-column>
          <el-table-column
            prop="file_type"
            label="File Type"
          ></el-table-column>
          <el-table-column prop="size" label="Size (px)">
            <template #default="scope"
              >{{ scope.row.width }} x
              {{ scope.row.height }}</template
            >
          </el-table-column>
          <el-table-column
            prop="error"
            label="Error"
          ></el-table-column>
          <el-table-column fixed="right" label="Action" width="90">
            <template #default="scope">
              <el-button
                size="small"
                type="danger"
                @click="deleteTrait(props, scope)"
                >Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-table-column>
    <el-table-column width="48px">
      <template #default="props">
        <el-icon v-if="props.row.loading" class="is-loading"
          ><Loading
        /></el-icon>
        <el-icon
          class="expand-icon"
          v-else
          @click="loadTraits(props.row)"
          ><ArrowRight
        /></el-icon>
      </template>
    </el-table-column>
    <el-table-column label="Layer Name" prop="layer_name" />
    <el-table-column
      label="Layer Index"
      prop="layer_index"
      sortable
    />
    <el-table-column label="Count" sortable>
      <template #default="scope">
        {{
          scope.row.traits ? scope.row.traits.length : scope.row.count
        }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ArrowRight, Loading } from '@element-plus/icons-vue';
import Trait from '@/components/Trait';
export default {
  components: {
    ArrowRight,
    Loading,
    Trait,
  },
  props: {
    dataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      expandRowKeys: [],
    };
  },
  computed: {
    tableData() {
      return [
        ...new Set(this.dataSource.map((item) => item.layer_name)),
      ].map((item) => {
        const traits = this.dataSource.filter(
          (childItem) => childItem.layer_name === item
        );
        return {
          layer_name: item,
          layer_index: traits?.[0]?.layer_index,
          count: traits.length,
        };
      });
    },
  },
  methods: {
    loadTraits(row) {
      if (!row.traits) {
        row.loading = true;
        const traits = this.dataSource.filter(
          (childItem) => childItem.layer_name === row.layer_name
        );
        const promises = traits.map((grandItem) => {
          return new Promise((resolve) => {
            // 读文件
            const reader = new FileReader();
            reader.readAsDataURL(grandItem.raw);
            reader.onload = ({ target }) => {
              const base64 = target.result;
              grandItem.image = base64;
              let image = new Image();
              image.src = base64;
              image.onload = () => {
                // 获取文件的宽高
                grandItem.width = image.width;
                grandItem.height = image.height;
                // 压缩图片生产 thumbnail
                const scale = image.width / image.height;
                const canvas = document.createElement('canvas');
                canvas.width = 100;
                canvas.height = canvas.width / scale;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(
                  image,
                  0,
                  0,
                  canvas.width,
                  canvas.height
                );
                grandItem.thumbnail = canvas.toDataURL();
                resolve(grandItem);
              };
            };
          });
        });
        Promise.all(promises).then((res) => {
          row.traits = res;
          row.loading = false;
          row.expanded = true;
          this.expandRowKeys.push(row.layer_name);
        });
      } else {
        const index = this.expandRowKeys.findIndex(
          (item) => item === row.layer_name
        );
        if (index === -1) {
          this.expandRowKeys.push(row.layer_name);
          row.expanded = true;
        } else {
          this.expandRowKeys.splice(index, 1);
          row.expanded = false;
        }
      }
    },
    deleteTrait(props, scope) {
      props.row.traits.splice(scope.$index, 1);
      this.$emit('delete', scope.row);
    },
    rowClassName({ row }) {
      if (row.expanded) {
        return 'row--expanded';
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table--inner {
  margin-top: -8px;
  margin-bottom: -9px;
  :deep(th) {
    background: #f5f7fa !important;
  }
}
:deep(.row--expanded) {
  .expand-icon {
    transform: rotate(90deg);
  }
}
:deep(.expand-icon) {
  cursor: pointer;
  transition: all 0.3s;
}
:deep(.el-table__expand-icon) {
  display: none;
}
</style>
