<template>
  <div class="card">
    <div class="frame">
      <div
        :style="{
          backgroundImage: `url(${
            aliOSSImage(dataSource.cover_url, 'w400') ||
            require('@/assets/images/thumbnail-art.png')
          })`,
        }"
        class="image"
      ></div>
      <div class="controls">
        <div class="buttons">
          <el-icon @click="$emit('editCollection')">
            <Setting />
          </el-icon>
          <el-icon @click="operateCollection">
            <Operation />
          </el-icon>
          <!-- <el-icon @click="generateNFTs">
            <Promotion />
          </el-icon> -->
          <el-icon @click="deleteCollection">
            <Delete />
          </el-icon>
        </div>
      </div>
    </div>
    <div class="name">
      <el-input
        v-model="form.model.name"
        @blur="form.model.name = dataSource.collection_name"
        @keyup.enter="updateName"
      ></el-input>
    </div>
  </div>
</template>

<script>
import { ElMessageBox, ElNotification } from 'element-plus';
import { Setting, Operation, Delete } from '@element-plus/icons-vue'; // , Promotion
import { aliOSSImage } from '@/utils';
import { updateCollection, deleteCollection } from '@/api';
export default {
  components: {
    Setting,
    Operation,
    // Promotion,
    Delete,
  },
  props: {
    dataSource: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        model: {
          name: '',
        },
      },
    };
  },
  watch: {
    'dataSource.collection_name': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.initFormModel();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initFormModel();
  },
  methods: {
    aliOSSImage,
    initFormModel() {
      this.form.model.name = this.dataSource.collection_name;
    },
    operateCollection() {
      const { collection_id } = this.dataSource;
      this.$router.push({
        path: `/${collection_id}/workspace`,
      });
    },
    // generateNFTs () {
    //   const { collection_id } = this.dataSource;
    //   const { href } = this.$router.resolve({
    //     path: `/${collection_id}/generate`,
    //   });
    //   window.open(href, '_blank');
    // },
    deleteCollection() {
      const { collection_id, collection_name } = this.dataSource;
      ElMessageBox.confirm(
        `Are you sure you want to archive "${collection_name}"? It will no longer appear in your collection list.`,
        'Are you sure?',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'el-button--danger danger',
          center: true,
          autofocus: false,
        }
      )
        .then(() => {
          deleteCollection(collection_id).then(([err]) => {
            if (!err) {
              this.$emit('deleteCollection');
            }
          });
        })
        .catch(() => {});
    },
    updateName() {
      const { name } = this.form.model;
      const { collection_id } = this.dataSource;
      if (name) {
        updateCollection(collection_id, {
          ...this.dataSource,
          collection_name: name,
        }).then(([err]) => {
          if (!err) {
            ElNotification({
              title: 'Success',
              type: 'success',
            });
            this.$emit('updateName', name);
          }
        });
      } else {
        ElNotification({
          title: 'Error',
          message: 'Collection name is required',
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url('~@/assets/css/function.less');
.card {
  width: 100%;
  border: 4px solid #000;
  border-radius: 14px;
  overflow: hidden;
  &:hover {
    & > .frame {
      & > .image {
        transform: scale(1.2);
      }
      & > .controls {
        opacity: 1;
        & > .buttons {
          display: block;
        }
      }
    }
  }

  & > .frame {
    position: relative;
    width: 224px;
    height: 224px;
    overflow: hidden;
    .transparent_background(20);
    & > .image {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      transition: all 0.3s;
    }

    & > .controls {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 0, 255, 0.5);
      opacity: 0;
      transition: all 0.3s;

      & > .buttons {
        display: none;
        width: 76px;

        .el-icon {
          margin: 3px;
          padding: 3px;
          border: 1px solid #fff;
          border-radius: 6px;
          font-size: 32px;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            background-color: #fff;
            color: #9c27b0;
          }
        }
      }
    }
  }

  & > .name {
    padding: 14px;
    background-color: indigo;
    border-top: 4px solid #000;
    :deep(.el-input) {
      .el-input__wrapper {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        .el-input__inner {
          height: 20px;
          line-height: 20px;
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
}
</style>

<style lang="less">
.danger {
  background-color: @danger;
  border-color: @danger;
}
</style>
