<template>
  <div class="list">
    <ul v-if="loading">
      <el-skeleton v-for="(item, index) of 50" :key="index" style="width: 200px" animated>
        <template #template>
          <el-skeleton-item variant="image" style="width: 200px; height: 200px" />
        </template>
      </el-skeleton>
    </ul>
    <ul v-else-if="formattedList.length">
      <li v-for="(item) of formattedList" :key="item.id" @click="showTokenDetail(item)">
        <div class="token-wrapper">
          <Token :rawUrl="item.raw_url" :traits="item.traits" />
          <div v-if="item.added" class="added-mask">
            <span>ADDED</span>
          </div>
        </div>
        <div class="desc">
          #{{ item.index }}
        </div>
        <div class="controls">
          <el-icon class="star" @click.stop="saveToCloud(item)">
            <StarFilled />
          </el-icon>
        </div>
      </li>
    </ul>
    <el-empty v-else :image-size="200" />
  </div>
  <el-dialog :model-value="dialog.visible" :append-to-body="true" class="components_dialog_token_detail" width="1000px"
    destroy-on-close @close="dialog.visible = false">
    <TokenDetail :rawUrl="dialog.item.raw_url" :traits="dialog.item.traits" @replaceTrait="replaceTrait" />
    <div class="corner_btn">
      <el-icon class="star" @click="saveToCloud(dialog.item)">
        <StarFilled />
      </el-icon>
    </div>
  </el-dialog>
  <div :class="{
  'tools--fixed--expanded': tools.visible,
}" class="tools--fixed">
    <div v-if="tools.visible" class="main">
      <div class="close">
        <el-icon @click="tools.visible = false">
          <Close />
        </el-icon>
      </div>
      <div class="content">
        <ButtonWithProgress :loading="uploadedTokens.loading" :paused="uploadedTokens.paused"
          :progress="uploadedTokens.progress" :total="uploadedTokens.total" :controllable="true"
          label="Add all tokens to cloud" size="large" @start="startUpload" @pause="pauseUpload"
          @cancel="cancelUpload" />
      </div>
    </div>
    <el-icon v-else class="icon" @click="tools.visible = true">
      <Tools />
    </el-icon>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { ElNotification, ElMessageBox } from 'element-plus';
import { StarFilled, Tools, Close } from '@element-plus/icons-vue';
import Token from '@/components/Token';
import TokenDetail from '@/components/TokenDetail';
import ButtonWithProgress from '@/components/ButtonWithProgress';
import { aliOSSImage } from '@/utils';
import { cancelCloudNFT } from '@/api';
export default {
  components: {
    StarFilled,
    Tools,
    Close,
    Token,
    TokenDetail,
    ButtonWithProgress,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    pagination: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: {
        visible: false,
        traits: [],
      },
      tools: {
        visible: false,
      },
      uploadedTokens: {
        loading: false,
        paused: false,
        progress: 0,
        total: 1,
        networkErrorTimes: 0,
      },
      timer: null,
    };
  },
  computed: {
    ...mapState('collection', {
      collectionId: ({ id }) => id,
    }),
    ...mapState('traits', {
      traits: ({ list }) => list,
    }),
    ...mapState('cloudTokens', {
      cloudCount: ({ count }) => count,
    }),
    ...mapState('presetTokens', ['list', 'count']),
    ...mapGetters('traits', ['layers']),
    formattedList() {
      console.log('formattedList-this.list', this.list);
      return this.list.map((item) => {
        return {
          id: item.traits.map(({ trait_id }) => trait_id).join(','),
          traits: item.traits.map((childItem) => {
            // 绑定动态素材
            const trait =
              this.traits.find(
                (findItem) => findItem.trait_id === childItem.trait_id
              ) || childItem;
            return {
              ...trait,
              layer_url: aliOSSImage(trait.layer_url, 'w400'),
            };
          }),
          index: item.index,
          force_index: item.force_index,
          raw_url: item.raw_url,
          added: !!item.nft_status
        };
      });
    },
  },
  watch: {
    loading: function (newVal, oldVal) {
      // 说明进行了重新 generate，所以重置 upload tokens 的状态
      if (newVal !== oldVal) {
        this.resetUpload();
      }
    },
  },
  methods: {
    startUpload() {
      this.resetUpload();
      this.uploadedTokens.loading = true;
      this.saveAllToCloud()
        .catch(() => {
          ElNotification({
            type: 'error',
            title: 'NETWORK ERROR',
          });
          this.resetUpload();
        });
    },
    resetUpload() {
      clearTimeout(this.timer);
      this.uploadedTokens.loading = false;
      this.uploadedTokens.paused = false;
      this.uploadedTokens.progress = 0;
      this.uploadedTokens.networkErrorTimes = 0;
    },
    pauseUpload() {
      this.uploadedTokens.paused = !this.uploadedTokens.paused;
      if (this.uploadedTokens.paused) {
        clearTimeout(this.timer);
      } else {
        this.saveAllToCloud();
      }
    },
    cancelUpload() {
      if (this.uploadedTokens.progress === 1001) {
        this.resetUpload();
      } else {
        ElMessageBox.confirm(
          'proxy will permanently cancel the upload. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
        )
          .then(() => {
            this.resetUpload();
            return cancelCloudNFT({
              collection_id: this.collectionId,
            });
          })
      }

    },
    saveAllToCloud() {
      clearTimeout(this.timer);
      return this.$store
        .dispatch(
          'cloudTokens/saveAllToCloud',
          {}
        )
        .then(res => {
          console.log(res);
          this.uploadedTokens.progress = Number(res.data.current);
          this.uploadedTokens.total = Number(res.data.count);
          if (Number(res.progress) < 100) {
            this.timer = setTimeout(() => {
              this.saveAllToCloud();
            }, 5000);
          }
        });
    },
    saveToCloud(nft, batch) {
      const { traits, force_index } = nft;
      console.log('saveToCloud', { traits }, batch);
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(
            'cloudTokens/addToken',
            // 去除空图层
            traits
              .map((item) => item.trait_id)
              .filter((item) => !item.startsWith('blank-'))
          )
          .then(
            (res) => {
              console.log('res', res.data);
              console.log(force_index);
              const list = this.list.map(nft => Object.assign(nft, nft.force_index === force_index ? res.data : {}));
              console.log(list);
              this.$store.commit('presetTokens/UPDATE_LIST', list);
              this.$store.commit(
                'cloudTokens/UPDATE_COUNT',
                this.cloudCount + 1
              );
              resolve();
              if (!batch) {
                ElNotification({
                  title: 'Success',
                  message: 'Save to cloud',
                  type: 'success',
                });
              }
            },
            (err) => {
              reject(err);
              if (!batch) {
                ElNotification({
                  title: err.message,
                  type: 'error',
                });
              }
            }
          );
      });
    },
    showTokenDetail(item) {
      this.dialog.visible = true;
      this.dialog.item = item;
    },
    replaceTrait({ newTrait, oldTrait }) {
      console.log('oldTrait', oldTrait);
      console.log('newTrait', newTrait);
      const { force_index } = this.dialog.item;
      const list = this.list.map((nft) => {
        if (force_index === nft.force_index) {
          for (const trait of nft.traits) {
            if (trait.trait_id === oldTrait.trait_id) {
              console.log('trait', trait);
              Object.assign(trait, newTrait);
            }
          }
          Object.assign(nft, {
            raw_url: '',
            nft_status: 0
          });
          this.dialog.item = nft;
        }
        return nft;
      });
      this.$store.commit('presetTokens/UPDATE_LIST', list);
    },
  },
};
</script>
<style lang="less" scoped>
@import '@/assets/css/function.less';

.list {
  &>ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0 0 20px;

    &>.el-skeleton {
      margin: 0 20px 20px 0;
    }

    &>li {
      position: relative;
      margin: 0 20px 20px 0;
      background-color: #fff;
      border-radius: 14px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
      overflow: hidden;
      transition: box-shadow 0.25s ease-in-out 0s;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);

        &>.token-wrapper {
          &>.token {
            transform: scale(1.2);
          }
        }

        &>.controls {
          height: 32px;
        }
      }

      &>.token-wrapper {
        position: relative;
        width: 200px;
        height: 200px;
        overflow: hidden;

        &>.token {
          position: relative;
          z-index: 1;
          transition: all 0.3s;
        }

        &>.added-mask {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 0, 255, 0.5);

          &>span {
            font-size: 42px;
            color: #fff;
            transform: rotate(-45deg);
          }
        }
      }

      &>.desc {
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        color: @font-color;
      }

      &>.controls {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0;
        overflow: hidden;
        transition: all 0.3s;

        &>.el-icon {
          flex: 1;
          height: 100%;
          font-size: 24px;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s;
        }

        &>.el-icon.star {
          background-color: @primary;
        }
      }
    }
  }
}

.corner_btn {
  &::before {
    border-right: 80px solid #ff01ff !important;
  }

  &>.el-icon {
    top: 32px;
    right: 0;
    line-height: 44px;
    font-size: 44px;
  }
}

.tools--fixed {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background-color: @white;
  border: 1px solid @border-color;
  border-radius: 16px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.08);
  transition: all 0.3s;

  &>.main {
    width: 100%;

    &>.close {
      padding: 8px;
      text-align: right;
      line-height: 0;

      &>.el-icon {
        cursor: pointer;
      }
    }

    &>.content {
      padding: 8px 16px 16px 16px;

      &>.el-button {
        width: 100%;
        background-color: @primary;
        border-color: @primary;
        color: @white;
      }
    }
  }

  &>.icon {
    padding: 6px;
    border-radius: 8px;
    background-color: @primary;
    font-size: 38px;
    color: @white;
    cursor: pointer;
  }
}

.tools--fixed--expanded {
  width: 250px;
  height: auto;
}
</style>
